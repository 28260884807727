export const LOAD_CUSTOMER_ALL = 'LOAD_CUSTOMER_ALL';
export const LOAD_CUSTOMER_ALL_SUCCESS = 'LOAD_CUSTOMER_ALL_SUCCESS';
export const LOAD_CUSTOMER_SUCCESS = 'LOAD_CUSTOMER_SUCCESS';
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';
export const SEARCH_CUSTOMER_ERROR = 'SEARCH_CUSTOMER_ERROR';
export const LOAD_ORDER_TYPES_SUCCESS = 'LOAD_ORDER_TYPES_SUCCESS';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const ADD_CUSTOMER_FOR_QUNATITY = 'ADD_CUSTOMER_FOR_QUNATITY';
export const REMOVE_CUSTOMER_FOR_QUNATITY = 'REMOVE_CUSTOMER_FOR_QUNATITY';
export const REMOVE_ALL_CUSTOMERS = 'REMOVE_ALL_CUSTOMERS';
