import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const DeleteButton = props => {
  return (
    <Tooltip title="Reset Password" placement={'top'}>
      <span>
        <button
          type="button"
          className="action-btn action-btn--small mr-2 k-button"
          onClick={props.resetPassword}
          disabled={props.disabled}
        >
          <i className="ri-lock-2-line"></i>
        </button>
      </span>
    </Tooltip>
  );
};

export default DeleteButton;
