import * as types from '../../actions/factory/actionTypes';
import initialState from '../initialState';

export default function factoryReducer(state = initialState.factories, action) {
  switch (action.type) {
    case types.LOAD_ALL_FACTORY_SUCCESS:
      return { ...state, allFactories: action.data };
    case types.LOAD_FACTORY_SUCCESS:
      return { ...state, selectedFactory: action.data };

    case types.ADD_TO_FACTORY:
      return { ...state, add_to_factory: action.data };

    case types.LOAD_FACTORY_BY_VENDOR:
      return { ...state, factory_by_vendor: action.data };
    default:
      return state;
  }
}
