import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../redux/actions/auth/authActions';
import NotificationContainer from '../components/Shared/NotificationContainer';
import AuthForm, { STATE_LOGIN, STATE_SIGNUP,STATE_FORGOT } from '../components/AuthForm';

class AuthPage extends React.Component {
  state = {
    isWorking: false,
  };

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else if (authState === STATE_SIGNUP){
      this.props.history.push('/signup');
    }
    else if(authState === STATE_FORGOT){
      this.props.history.push('/forgot');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  handleOnLoginClick = (username, password) => {
    this.setState({ isWorking: true });

    this.props.actions.login({ username, password }).catch(() => {
      this.setState({ isWorking: false });
    });
  };

  render() {
    return (
      <Row className="login-bg">
        <Col md={6} lg={4}>
          <Card className="login-card" body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              onLoginClick={this.handleOnLoginClick}
              isWorking={this.state.isWorking}
              loading={this.props.loading}
            />
          </Card>
        </Col>
        <NotificationContainer />
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(authActions.login, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
