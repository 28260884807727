import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { clearUserPermissions } from '../../redux/actions/userPermissions/userPermissionsAction';
import { RiMenu2Line } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { history } from '../../utils/history';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { useSelector } from 'react-redux';
import ManagementButton from './managementButton';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  // handleSidebarControlButton = event => {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  //   if (
  //     !document
  //       .querySelector('.cr-sidebar')
  //       .classList.contains('cr-sidebar--open')
  //   ) {
  //     document.querySelector('.cr-sidebar').classList.add('cr-sidebar--close');
  //   } else {
  //     document
  //       .querySelector('.cr-sidebar')
  //       .classList.remove('cr-sidebar--close');
  //   }
  // };

  signOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    //localStorage.removeItem('permissions');
    //window.localStorage.clear();
    localStorage.setItem('permissions', JSON.stringify({}));
    this.props.actions.clearUserPermissions();
    history.push('/');
  };

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button className="toggle-left-menu" onClick={this.props.onClick}>
            <RiMenu2Line size={25} />
          </Button>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}

        <Nav navbar className={bem.e('nav-right')}>
          {/* <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem> */}

          <NavItem>
            <NavLink id="Popover2" className="header-user">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click mr-2"
              />
              <span>{localStorage.display_name}</span>
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0"
              style={{ minWidth: 200, borderRadius: 3 }}
            >
              <PopoverBody className="p-0 border-light">
                <ListGroup flush>
                  <ListGroupItem
                    tag="button"
                    action
                    className="border-light"
                    onClick={() => {
                      this.signOut();
                    }}
                  >
                    <FiLogOut className="mr-2" /> Signout
                  </ListGroupItem>
                </ListGroup>
              </PopoverBody>
            </Popover>
          </NavItem>
          <NavItem>
            <ManagementButton />
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

const mapDisptachToProps = dispatch => {
  return {
    actions: {
      clearUserPermissions: bindActionCreators(clearUserPermissions, dispatch),
    },
  };
};
export default connect(null, mapDisptachToProps)(Header);
