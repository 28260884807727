import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

class NotificationContainer extends Component {
  componentWillReceiveProps(newProps) {
    const { message, level } = newProps.notification;

    if (!this.notificationSystem) {
      return;
    }

    this.notificationSystem.addNotification({
      message,
      level,
    });
  }

  render() {
    return (
      <NotificationSystem
        allowHTML={true}
        dismissible={false}
        ref={notificationSystem =>
          (this.notificationSystem = notificationSystem)
        }
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification,
  };
}

export default connect(mapStateToProps)(NotificationContainer);
