import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const DeleteButton = props => {
  return (
    <Tooltip title={props.title || 'Delete'} placement={'top'}>
      <span>
        <button
          type="button"
          className="action-btn action-btn--small mr-2 k-button action-btn--delete"
          onClick={props.delete}
          disabled={props.disabled}
        >
          <i className="ri-delete-bin-line"></i>
        </button>
      </span>
    </Tooltip>
  );
};

export default DeleteButton;
