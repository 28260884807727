import { cost_request } from './costRequest';
import { userPermissions } from './userPermissionInitialState';
import { getGridColumns } from '../../pages/masterData/excelData';
let grid_data = getGridColumns();
export default {
  columns: grid_data,
  userPermissions,
  duty_free_switch: false,
  selectedTabIndex: {},
  addColors: {},
  colorList: [],
  permissions: JSON.parse(localStorage.getItem('permissions')),
  attachemtImages: [],
  courses: [],
  authors: [],
  apiCallsInProgress: 0,
  auth: JSON.parse(localStorage.getItem('auth')) || {
    access_token: '',
  },
  customers: {
    allCustomers: [],
    orderTypes: [],
    selectedCustomer: {},
    selected_customer_list: [],
  },
  vendors: {
    allVendors: [],
    selectedVendor: {},
    factories_by_vendors: [],
  },
  costs: [],
  brands: {
    data: [],
  },
  countries: [],
  warehouses: [],
  logStatus: [],
  divisions: {
    data: [],
  },
  departments: {
    data: [],
  },
  associate_pack: [],
  auditors: [],
  shipping_log: {
    shipping_logs: [],
    shippingLog_notes: [],
    shippingLogMergedNotes: [],
    shippingLog_documents: [],
    selectedShippingLog: {},
    shipping_tags: [],
    shipping_mail_template: {},
    shipping_summary: {},
  },
  receiving_log: {
    receiving_logs: [],
    selectedReceivingLog: {},
    receivingLog_documents: [],
    receivingLog_notes: [],
    receivingLogMergedNotes: [],
    receiving_tags: [],
    receiving_mail_template: {},
    receiving_summary: [],
  },
  fabrics: [],
  fabrics_types: [],
  hst_codes: [],
  materials: [],
  how_to_make: [],
  table: {
    current_page: 1,
    data: [],
    per_page: '10',
    to: 0,
    total: 0,
  },
  body_options: [],
  locations: [],
  cost_request,

  associate_packs: [],
  status_types: [],
  order_types: [],
  factories: {
    allFactories: [],
    selectedFactory: {},
    add_to_factory: [],
    factory_by_vendor: [],
  },
  images: [],
  userManagement: {
    email_list: [],
    usernames: [],
    deleted_usernames: [],
    deleted_email_list: [],
    linesheets: {},
    step: 0,
    //roleName:'',
    allUsers: [],
    allRoles: [],
    userTypes: [],
    error: {},
    loading: false,
    selectedUser: {},
    createRoleForm: {
      id: 0,
      slug: '',
      name: '',
      description: '',
      role_color: '',
      rank: 0,
      create: 'yes',
    },
    editRoleForm: {},

    createUserForm: {
      user_level: 1,
      default_dept: 'default_dep',
      is_user_can_create_linesheet: 0,
      activation_start_date: null,
      activation_end_date: null,
      permissions: ['permissions'],
      order_permission: 0,
      inventory_access: 0,
      order_without_inventory: 0,
      warehouses: [],
      is_default_password: 1,
      roles: [],
      departments: [],
      supervisor_id: 0,
      line_sheets: [],
    },
    userForm: {},
    supervisors: [],
    rolePermissions: [],
  },
  rightPanel: {
    status: null,
  },
  sync: {},
  products: {
    amtItems: [],
    companies: [],
    divisions: [],
    seasons: [],
    vendors: [],
    productDetails: [],
    images: [],
    scanHistory: {},
    scannedItems: [],
    report: [],
    latestReport: [],
  },
  chargeback: {
    chargeback: [],
    selectedChargeback: {},
    chargeback_tags: [],
    users: [],
    chargebackLogDocs: [],
    chargebackEmailTemplate: [],
    chargebackLogNotes: [],
    test: 'testdata',
  },
};
