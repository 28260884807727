import React from 'react';

const Loader = () => {
  return (
    <div className="k-loading-mask" style={{ position: 'fixed' }}>
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
};

export default Loader;
