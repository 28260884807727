import React from 'react';
import logo from './../assets/img/logo.png';

import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

class ResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      isError: '',
    };
  }

  get isLogin() {
    //return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    //return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    const email = localStorage.getItem('email');
    const userID = localStorage.getItem('id');
    event.preventDefault();
    if (!this.state.password || !this.state.confirmPassword) {
      // alert('Please fill required fields');
      this.setState({ isError: 'Required' });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ isError: 'Mismatch' });
      return;
    }

    this.props.resetPassword({
      id: userID,
      email: email,
      password: this.state.confirmPassword,
    });
    //this.props.onLoginClick(this.state.username, this.state.password);
    this.setState({ isError: '' });
  };

  handleChange = event => {
    const { name, value } = event.target;
    console.log(event);
    this.setState({ [name]: value });
  };

  renderButtonText() {
    const { buttonText, isWorking } = this.props;

    return (
      <React.Fragment>
        {' '}
        {isWorking && <span className="k-icon  k-i-loading"></span>} Reset{' '}
        <i className="ri-arrow-right-line"></i>
      </React.Fragment>
    );
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
      loading,
    } = this.props;
    console.log(this.state.password, this.state.confirmPassword);
    const username = localStorage.getItem('username');
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="logo-text pb-4">
          <img
            src={logo}
            className="rounded"
            style={{ height: 60, cursor: 'pointer', marginBottom: 20 }}
            alt="logo"
            onClick={onLogoClick}
          />
        </div>
        <div className="alert alert-success p-2 m-0 ">
          <strong>Welcome! {` ${username}`} </strong>. Reset your default
          password.
          <p></p>
        </div>
        <br />
        <FormGroup>
          <Input
            {...passwordInputProps}
            name={passwordLabel}
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
          />
          <Label for={passwordLabel}>{passwordLabel}</Label>
          {this.state.isError === 'Required' && (
            <p className="text-danger p-1  position-absolute z-index-1000 ">
              Please fill required fields
            </p>
          )}
        </FormGroup>
        <br />
        <FormGroup>
          <Input
            {...confirmPasswordInputProps}
            name={confirmPasswordLabel}
            value={this.state.confirmPassword}
            onChange={e => this.setState({ confirmPassword: e.target.value })}
          />
          <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
          {this.state.isError === 'Required' && (
            <p className="text-danger p-1  position-absolute z-index-1000 ">
              Please fill required fields
            </p>
          )}
          {this.state.isError === 'Mismatch' && (
            <p className="text-danger p-1  position-absolute z-index-1000 ">
              Password mismatch
            </p>
          )}
        </FormGroup>
        <br />
        <div className="d-flex align-items-center justify-content-between">
          <a
            href="/login"
            className="forgot-pass"
            onClick={() => this.props.history.push('/login')}
          >
            Back to login
          </a>
          <Button
            type="submit"
            disabled={this.props.isWorking}
            size="lg"
            className="login-btn border-0"
            block
          >
            {this.renderButtonText()}
          </Button>
        </div>
        {children}
      </Form>
    );
  }
}

ResetForm.defaultProps = {
  passwordLabel: 'New Password',
  passwordInputProps: {
    type: 'password',
    placeholder: ' ',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: ' ',
  },
};

export default ResetForm;
