import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

const PrintButton = props => {
  return (
    <Tooltip title="Print" placement={'top'}>
      <button className="action-btn action-btn--small mr-2 k-button" onClick={() => {
        props.clicked(props.dataItem)
     }}>
      <i className="ri-printer-fill"></i>
      </button>
    </Tooltip>
  );
};

export default PrintButton;
