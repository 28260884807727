import * as types from '../../actions/actionTypes';
import initialState from '../initialState';

export default function receivingLogReducer(
  state = initialState.receiving_log,
  action,
) {
  switch (action.type) {
    case types.LOAD_RECEIVINGLOG_ALL_SUCCESS:
      return { ...state, receiving_logs: action.data };
    case types.LOAD_RECEIVINGLOG_SUCCESS:
      return { ...state, selectedReceivingLog: action.data };
    case types.LOAD_RECEIVINGLOG_DOCUMENTS_ALL_SUCCESS:
      return { ...state, receivingLog_documents: action.data };
    case types.LOAD_RECEIVING_LOG_NOTES_ALL_SUCCESS:
      return {
        ...state,
        receivingLog_notes: action.data,
      };
    case types.LOAD_RECEIVING_LOG_MERGED_NOTES_ALL_SUCCESS:
      return {
        ...state,
        receivingLogMergedNotes: action.data,
      };

    case types.LOAD_RECEIVING_TAGS:
      return {
        ...state,
        receiving_tags: action.data,
      };
    case types.LOAD_RECEIVING_LOG_MAIL_TEMPLATE:
      return {
        ...state,
        receiving_mail_template: action.data,
      };
    case types.LOAD_RECEIVING_LOG_SUMMARY:
      return {
        ...state,
        receiving_summary: action.data,
      };
    default:
      return state;
  }
}
