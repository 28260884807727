import { Collapse, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { NavGroupHeader } from './NavGroupHeader';
import PropTypes from 'prop-types';

export const NavGroup = ({
  groupName,
  mainIcon,
  navLinks,
  bem,
  open,
  onMenuToggle,
  activeGroupName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
    onMenuToggle && onMenuToggle(groupName);
  };
  useEffect(() => {
    if (activeGroupName !== groupName) {
      setIsOpen(false);
    }
  }, [open, activeGroupName]);
  return (
    <>
      <NavItem
        className={isOpen ? `${bem.e('nav-item')} expanded` : bem.e('nav-item')}
        onClick={() => onToggle()}
      >
        <NavGroupHeader
          bem={bem}
          isOpen={isOpen}
          tittle={groupName}
          mainIcon={mainIcon}
        />
      </NavItem>
      <Collapse isOpen={isOpen}>
        {navLinks &&
          navLinks
            .filter(item => item.visible === true)
            .map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                  className="menu-title"
                >
                  {/* <Icon className={bem.e('nav-item-icon left-icon')} /> */}
                  <span style={{ paddingLeft: 30 }} className="menu-title">
                    {name}
                  </span>
                </BSNavLink>
              </NavItem>
            ))}
        {/*{!this.state.isSnAdmin && (*/}
        {/*  <NavItem className={bem.e('nav-item')}>*/}
        {/*    <BSNavLink*/}
        {/*      id={`navItem-mycost-handling`}*/}
        {/*      tag={NavLink}*/}
        {/*      to={'/costing'}*/}
        {/*      activeClassName="active"*/}
        {/*      exact={true}*/}
        {/*      className="menu-title"*/}
        {/*    >*/}
        {/*      <span className="menu-title">My Cost Handling</span>*/}
        {/*    </BSNavLink>*/}
        {/*  </NavItem>*/}
        {/*)}*/}
      </Collapse>
    </>
  );
};

NavGroup.prototype = {
  groupName: PropTypes.string,
  navLinks: PropTypes.array,
  bem: PropTypes.any,
  open: PropTypes.bool,
  onMenuToggle: PropTypes.func,
};
