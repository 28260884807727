import excelExportReducer from '../reducers/excelSettings/excelSettingsReducer';

export const CREATE_COURSE = 'CREATE_COURSE';
export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS';
export const LOAD_AUTHORS_SUCCESS = 'LOAD_AUTHORS_SUCCESS';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
export const DELETE_COURSE_OPTIMISTIC = 'DELETE_COURSE_OPTIMISTIC';

export * from './auth/actionTypes';
export * from './customer/actionTypes';
export * from './brand/actionTypes';
export * from './country/actionTypes';
export * from './division/actionTypes';
export * from './department/actionTypes';
export * from './associatePack/actionTypes';
export * from './fabric/actionTypes';
export * from './hstCode/actionTypes';
export * from './cost/actionTypes';
export * from './vendors/actionTypes';
export * from './bodyOptions/actionTypes';
export * from './auditors/actionTypes';
export * from './shippingLog/actionTypes';
export * from './product/actionTypes';
export * from './receivingLog/actionTypes';
export * from './chargeBack/actionTypes';
export * from './status/actionTypes';
export * from './factory/actionTypes';
