import * as types from './actionTypes';
// import {
//   beginApiCall,
//   apiCallError,
//   apiCallSuccess,
// } from '../apiStatusActions';
// import { addNotification } from '../notification/notificationActions';
// import { getAllUserPermissions } from '../../../api/userPermissions';
// import { history } from '../../../utils/history';

// export function getAllUserPermissionsAction() {
//   return function (dispatch) {
//     dispatch(beginApiCall());
//     return getAllUserPermissions()
//       .then(result => {
//         let data = {
//           // ml_dashboard:{},
//           // ml_shipping_entry:{},
//           // ml_receiving_entry:{},
//           // ml_mdm_customer:{},
//           // ml_mdm_division:{},
//           // ml_mdm_vendors:{},
//           // ml_mdm_warehouse:{},
//           // ml_linesheet:{},
//           // ml_retailer:{},
//           // ml_orders:{},
//           // mr_um_users:{},
//           // mr_um_users_roles:{},
//         };
//         try {
//           if (result.hasOwnProperty('data.roles[0].permissions') === false) {
//             data.permissions = result.data.roles[0].permissions;
//           } else {
//             data.permissions = {};
//           }
//         } catch (e) {
//           data.permissions = {};
//         }
//         //   try{
//         //     if(result.hasOwnProperty("result.data.roles[0].permissions") === false ){data.roles = result.data.roles[0].permissions; }
//         //   }catch(e){data.roles ={}}
//         //   try{
//         //     if(result.hasOwnProperty("result.data.menus.left['0'].permission") === false ){data.ml_dashboard = result.data.menus.left['0'].permission; }
//         //   }catch(e){data.ml_dashboard={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['1'].childs[0].permission") === false ){ data.ml_shipping_entry = result.data.menus.left['1'].childs[0].permission; }}catch(e){data.ml_shipping_entry={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['2'].childs[0].permission") === false ){ data.ml_receiving_entry = result.data.menus.left['2'].childs[0].permission; }}catch(e){data.ml_receiving_entry={}}
//         // try{
//         //  if(result.hasOwnProperty("result.data.menus.left['7'].childs[1].permission") === false ){  data.ml_mdm_customer = result.data.menus.left['7'].childs[1].permission; }}catch(e){data.ml_mdm_customer={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['7'].childs[4].permission") === false ){  data.ml_mdm_division = result.data.menus.left['7'].childs[4].permission; }}catch(e){data.ml_mdm_division={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['7'].childs[18].permission") === false ){    data.ml_mdm_vendors = result.data.menus.left['7'].childs[18].permission; }}catch(e){data.ml_mdm_vendors={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['7'].childs[20].permission") === false ){    data.ml_mdm_warehouse = result.data.menus.left['7'].childs[20].permission; }}catch(e){data.ml_mdm_warehouse={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['9'].permission") === false ){    data.ml_linesheet= result.data.menus.left['9'].permission; }
//         // }catch(e){data.ml_linesheet ={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['10'].permission") === false ){     data.ml_retailer= result.data.menus.left['10'].permission; }
//         // }catch(e){ data.ml_retailer={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.left['11'].permission") === false ){      data.ml_orders= result.data.menus.left['11'].permission; }
//         // }catch(e){data.ml_orders={}}
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.right['1'].childs[0].permission") === false ){      data.mr_um_users= result.data.menus.right['1'].childs[0].permission; } }catch(e){data.mr_um_users={} }
//         // try{
//         //   if(result.hasOwnProperty("result.data.menus.right['1'].childs[1].permission") === false ){      data.mr_um_users_roles= result.data.menus.right['1'].childs[1].permission; }}catch(e){ data.mr_um_users_roles={} }

//         dispatch({ type: types.LOAD_USER_PERMISSIONS, data });
//         localStorage.setItem('permissions', JSON.stringify(data));
//         dispatch(apiCallSuccess());
//       })
//       .catch(error => {
//         dispatch(addNotification(error, 'error'));
//         dispatch(apiCallError(error));
//       });
//   };
// }

export function clearUserPermissions() {
  return { type: types.CLEAR_USER_PERMISSIONS };
}
