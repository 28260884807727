import * as types  from '../../actions/userPermissions/actionTypes'
import initialState from "../initialState";



// const  userPemissions ={
                 
//         vendorUpdate:status,
//         roles:{
//           dashboardShipping:status,
//           dashboardReceiving:status,
//           dashboardChargeback:status,
//           menuHomeDashboard:status,
//          },
//         menuLeft:{
//             dashboardShipping:status,
//             dashboardReceiving:status,
//             dashboardChargeback:status,
//             shippingEntry:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//                 viewStatus:status,
//                 storeStatus:status,
//                 updateStatus:status,
//                 deleteStatus:status
//             },
//             ReceivingEntry:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//                 viewStatus:status,
//                 storeStatus:status,
//                 updateStatus:status,
//                 deleteStatus:status
//             },MDMCustomer:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             },
//             MDMDivision:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             }
//             ,MDMVendors:{
//                 VendorView:status,
//                 VendorStore:status,
//                 VendorUpdate:status,
//                 VendorDelete:status,
//                 warehouseDelete:status,
//                 factoryView:status,
//                 factoryStore:status,
//                 factoryUpdate:status,
//                 factoryDelete:status,
//             },MDMWarehouse:{
//                 view:status,
//                 store:status,
//                 update:status,
//             },
//             MDMLinesheet:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             },
//             MDMRetailer:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             },
//             MDMOrders:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             }
    
//         },
//         menuRight:{
//             UMUsers:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             },
//             UNRoles:{
//                 view:status,
//                 store:status,
//                 update:status,
//                 delete:status,
//             }
//         }
    
//       }




export default function userPermissionsReducer(state =initialState.permissions, action) {
  switch (action.type) {
 
      case types.LOAD_USER_PERMISSIONS:
            return{
              //...state,
              ...action.data
            }
            case types.CLEAR_USER_PERMISSIONS:
              return{
                //...state,
                permissions:{}
              }
          //  case types.ROLE_BASED_ACCESS_CONTROLLER_LOADER:
          //    return{
             
               
          //        ...state,
          //       role_based_access_controller_loader:false
               
          //    } 

            
    default:
      return state;
  }
}
