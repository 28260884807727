import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React from 'react';
import bigLogo from 'assets/img/logo-white.png';
import smallLogo from 'assets/img/logo-short.png';
import { MdImportantDevices } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import RightPropertyBar from './RightPropertyBar';
import NotificationContainer from './../Shared/NotificationContainer';
import ClickOutside from '../Shared/ClickOutside';

class MainLayout extends React.Component {
  // static isSidebarOpen() {
  //     return document
  //         .querySelector('.cr-sidebar')
  //         .classList.contains('cr-sidebar--open');
  // }

  // componentWillReceiveProps({ breakpoint }) {
  //     if (breakpoint !== this.props.breakpoint) {
  //         this.checkBreakpoint(breakpoint);
  //     }
  // }
  constructor() {
    super();
    this.state = {
      isOpen: true,
    };
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  // componentDidMount() {
  //   // this.checkBreakpoint(this.props.breakpoint);

  //   setTimeout(() => {
  //     if (!this.notificationSystem) {
  //       return;
  //     }

  //     this.notificationSystem.addNotification({
  //       title: <MdImportantDevices />,
  //       message: 'Welome to Studio Nazar',
  //       level: 'info',
  //     });
  //   }, 1500);
  // }

  // close sidebar when
  // handleContentClick = event => {
  //     // close sidebar if sidebar is open and screen size is less than `md`
  //     if (
  //         MainLayout.isSidebarOpen() &&
  //         (this.props.breakpoint === 'xs' ||
  //             this.props.breakpoint === 'sm' ||
  //             this.props.breakpoint === 'md')
  //     ) {
  //         this.openSidebar('close');
  //     }
  // };

  // checkBreakpoint(breakpoint) {
  //     switch (breakpoint) {
  //         case 'xs':
  //         case 'sm':
  //         case 'md':
  //             return this.openSidebar('close');

  //         case 'lg':
  //         case 'xl':
  //         default:
  //             return this.openSidebar('open');
  //     }
  // }

  // openSidebar(openOrClose) {
  //     if (openOrClose === 'open') {
  //         return document
  //             .querySelector('.cr-sidebar')
  //             .classList.add('cr-sidebar--open');
  //     }
  //     document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  // }

  render() {
    const { children } = this.props;

    return (
      <main className="cr-app bg-light">
        <Sidebar
          newClass={
            this.state.isOpen ? 'cr-sidebar--open' : 'cr-sidebar--closed'
          }
          navHover={this.state.isOpen ? 'rrr' : 'expanded'}
          src={this.state.isOpen ? bigLogo : smallLogo}
          onMouseEnter={this.state.isOpen ? () => {} : this.handleToggleSidebar}
        />
        <Content
          style={{ paddingBottom: '41px' }}
          fluid
          onClick={this.handleContentClick}
        >
          <Header onClick={this.handleToggleSidebar} />
          {children}
          <Footer />
        </Content>
        <RightPropertyBar />
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
        <NotificationContainer />
      </main>
    );
  }
}

export default MainLayout;
