import { ADD_NOTIFICATION } from './../actions/notification/actionTypes';

export default function notificationReducer(state = {}, action) {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return Object.assign({}, state, {
                message: action.message,
                level: action.level
            });

        default:
            return state;
    }
}