import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
//import { useSelector } from 'react-redux';
const AddButton = props => {
  const username = localStorage.getItem('display_name');
  const isAdmin = () => username === 'Developers Admin';

  // const isPermissions = useSelector(state =>
  //   state.role_based_access_controller !== null &&
  //   state.role_based_access_controller.permissions !== 'undifined' &&
  //   state.role_based_access_controller.hasOwnProperty('permissions') === true
  //     ? state.role_based_access_controller.permissions
  //     : {},
  // );
  const [tipName, setTipName] = useState('');
  const { group_id } = props.dataItem;
  useEffect(() => {
    if (props.buttons.includes('clone')) {
      setTipName('Clone');
    } else {
      setTipName('Add');
    }
  }, []);

  let status =
    isAdmin() ||
    group_id === 1 ||
    group_id === 2 ||
    group_id === 3 ||
    group_id === 4 ||
    group_id === 5 ||
    group_id === 6 ||
    group_id === 7 ||
    group_id === 8 ||
    group_id === 8 ||
    group_id === 9 ||
    group_id === 10 ||
    group_id === 11;
  // return (groupId === 1 && isPermissions['shipping.clone-send'] === true) ||
  //   (groupId === 2 && isPermissions['shipping.clone-received'] === true) ||
  //   (groupId === 3 && isPermissions['shipping.clone-routed'] === true) ||
  //   (groupId === 4 && isPermissions['shipping.clone-update-change'] === true) ||
  //   (groupId === 5 && isPermissions['shipping.clone-shipped'] === true) ||
  //   (groupId === 7 && isPermissions['receiving.clone-send'] === true) ||
  //   (groupId === 8 && isPermissions['receiving.clone-doc-received'] === true) ||
  //   (groupId === 9 && isPermissions['receiving.clone-arrived'] === true) ||
  //   (groupId === 10 && isPermissions['receiving.clone-unloaded'] === true) ||
  //   (groupId === 11 &&
  //     isPermissions['receiving.clone-shipment-received'] === true) ? (
  console.log(status);
  return (
    <Tooltip title={props.title || tipName} placement={'top'}>
      <span>
        <button
          className="action-btn action-btn--small mr-2 k-button"
          onClick={() => {
            props.clicked(props.dataItem);
          }}
          disabled={!status}
        >
          <i className="ri-file-copy-line"></i>
        </button>
      </span>
    </Tooltip>
  );
  //) : null;
};

export default AddButton;
