import * as types from '../../actions/userManagement/actionTypes';
import initialState from '../initialState';

export default function userManagementReducer(
  state = initialState.userManagement,
  action,
) {
  switch (action.type) {
    case types.LOAD_ALL_USERS:
      return { ...state, allUsers: action.data };
    case types.LOAD_ALL_ROLES:
      return { ...state, allRoles: action.data };
    case types.LOAD_ALL_SUPERVISORS:
      return { ...state, supervisors: action.data };
    case types.ERROR:
      return { ...state, error: { message: action.data } };
    case types.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    case types.SELECT_USER:
      return mapGetUserToForm(state, action.data);
    case types.UP_STEPPER:
      return { ...state, step: state.step + 1 };
    case types.DOWN_STEPPER:
      return {
        ...state,

        step: state.step - 1,
      };
    case types.CHANGE_ACTIVE_STEP:
      return { ...state, step: action.data };
    case types.TOGGLE_PASSWORD:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          is_default_password: action.data,
        },
      };
    case types.ADD_ORDER_PERMISSION:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          order_permission: action.data,
        },
      };

    case types.ADD_USER_CAN_CREATE_LINESHEET:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          is_user_can_create_linesheet: action.data,
        },
      };
    case types.ADD_INVENTORY_ACCESS:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          inventory_access: action.data,
        },
      };

    case types.ADD_ORDER_WITHOUT_INVENTORY:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          order_without_inventory: action.data,
        },
      };
    case types.ADD_LINESHEET:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          line_sheets: state.createUserForm.line_sheets
            ? [...state.createUserForm.line_sheets, action.data]
            : [action.data],
        },
      };

    case types.LOAD_ALL_USERNAMES:
      return {
        ...state,
        usernames: action.data.data.map(i => i.username),
        email_list: action.data.data.map(i => i.email),
      };

    case types.REMOVE_LINESHEET:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          line_sheets: state.createUserForm.line_sheets.filter(
            id => id !== action.data,
          ),
        },
      };
    case types.ADD_ROLE:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          roles: state.createUserForm.roles
            ? [...state.createUserForm.roles, action.data]
            : [action.data],
        },
      };
    case types.REMOVE_ROLE:
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          roles: state.createUserForm.roles.filter(
            role => role !== action.data,
          ),
        },
      };
    case types.UPDATE_USER:
      let ids = state.userForm.linesheets.map(item => item.id);
      return {
        ...state,
        createUserForm: {
          ...state.createUserForm,
          roles: state.userForm.roles ? [...state.userForm.roles] : [],
          warehouses: state.userForm.warehouses
            ? [...state.userForm.warehouses]
            : [],
          departments: state.userForm.departments
            ? [...state.userForm.departments]
            : [],
          supervisor_id: state.userForm.supervisor_id
            ? state.userForm.supervisor_id
            : 0,
          line_sheets: state.userForm.linesheets ? [...ids] : [],
          is_user_can_create_linesheet:
            state.userForm.is_user_can_create_linesheet === 1 ? true : false,
          order_permission:
            state.userForm.order_permission === 1 ? true : false,
          inventory_access:
            state.userForm.inventory_access === 1 ? true : false,
          order_without_inventory:
            state.userForm.order_without_inventory === 1 ? true : false,
        },
      };
    case types.ADD_WAREHOUSE:
      return addWareHouse(state, action.data);
    case types.REMOVE_WAREHOUSE:
      return removeWarehouse(state, action.data);
    case types.SET_DEFAULT__WAREHOUSE:
      return setDefaultToWarehouse(state, action.data);
    case types.CLEAR_WAREHOUSE_DEFAULTS: {
      return clearWareHouseDefaults(state);
    }
    case types.UPDATE_USER_FORM:
      return {
        ...state,
        createUserForm: { ...state.createUserForm, ...action.data },
      };
    case types.UPDATE_USER_ROLE_FORM:
      return {
        ...state,
        createRoleForm: {
          id: state.editRoleForm.id ? state.editRoleForm.id : '',
          slug: state.editRoleForm.slug ? state.editRoleForm.slug : '',
          name: state.editRoleForm.name ? state.editRoleForm.name : '',
          description: state.editRoleForm.description
            ? state.editRoleForm.description
            : '',
          role_color: state.editRoleForm.role_color
            ? state.editRoleForm.role_color
            : '',
          rank: state.editRoleForm.rank ? state.editRoleForm.rank : 0,
        },
      };
    case types.SELECTED_USER_ROLE:
      return {
        ...state,
        editRoleForm: {
          ...action.data.role,
        },
      };

    case types.CLEAR_USER_ROLE_FORM:
      return {
        ...state,
        createRoleForm: initialState.userManagement.createRoleForm,
        editRoleForm: initialState.userManagement.editRoleForm,
      };
    case types.LOAD_ALL_USER_TYPES: {
      return {
        ...state,
        userTypes: action.data,
      };
    }

    case types.LOAD_ALL_LINE_SHEETS: {
      return {
        ...state,
        linesheets: action.data,
      };
    }
    case types.CLEAR_ALL_FORMS: {
      return {
        ...state,
        userForm: {},
        createUserForm: initialState.userManagement.createUserForm,
      };
    }
    case types.LOAD_ALL_PERMISSIONS: {
      return {
        ...state,
        rolePermissions: action.data,
      };
    }
    case types.SELECT_MENU_IN_MODULE: {
      return selectMenuModule(state, action.data);
    }
    case types.TOGGLE_PERMISSION: {
      console.log('action.data', action.data);
      return togglePermission(state, action.data);
    }
    default:
      return state;
  }
}

// function addLineSheet(state, data) {
//   if (state.createUserForm.line_sheets) {
//     return {
//       ...state,
//       createUserForm: {
//         ...state.createUserForm,
//         line_sheets: [...state.createUserForm.line_sheets, data],
//       },
//     };
//   } else {
//     return {
//       ...state,
//       createUserForm: {
//         ...state.createUserForm,
//         line_sheets: [data],
//       },
//     };
//   }
// }

// function removeLineSheet(state, data) {
//   return {
//     ...state,
//     createUserForm: {
//       ...state.createUserForm,
//       line_sheets: state.createUserForm.line_sheets.filter(
//         id =>
//           id !== data.id

//       ),
//     },
//   };
// }

function addWareHouse(state, data) {
  if (state.createUserForm.warehouses) {
    return {
      ...state,
      createUserForm: {
        ...state.createUserForm,
        warehouses: [...state.createUserForm.warehouses, data],
      },
    };
  } else {
    return {
      ...state,
      createUserForm: {
        ...state.createUserForm,
        warehouses: [data],
      },
    };
  }
}

function removeWarehouse(state, data) {
  return {
    ...state,
    createUserForm: {
      ...state.createUserForm,
      warehouses: state.createUserForm.warehouses.filter(
        record =>
          record.warehouse_id !== data.warehouse_id &&
          record.module_id !== data.module_id,
      ),
    },
  };
}

function clearWareHouseDefaults(state) {
  const wareHouses = state.createUserForm.warehouses.map(record => {
    const { warehouse_id, module_id } = record;

    return {
      warehouse_id,
      module_id,
    };
  });
  return {
    ...state,
    createUserForm: {
      ...state.createUserForm,
      warehouses: wareHouses,
    },
  };
}

function setDefaultToWarehouse(state, data) {
  //  Clear current default
  const updatedState = {
    ...state,
    createUserForm: {
      ...state.createUserForm,
      warehouses: state.createUserForm.warehouses.map(record => {
        return { ...record, is_default: 0 };
      }),
    },
  };

  const index = updatedState.createUserForm.warehouses.findIndex(
    record =>
      record.module_id === data.module_id &&
      record.warehouse_id === data.warehouse_id,
  );
  if (index !== -1) {
    updatedState.createUserForm.warehouses[index]['is_default'] = 1;
  }
  return updatedState;
}

function mapGetUserToForm(state, data) {
  const userForm = {};
  for (const key in data) {
    if (key === 'user_type') {
      userForm[key] = data[key].id;
    } else if (key === 'roles') {
      userForm[key] = data[key].map(recoord => recoord.id);
    } else if (key === 'warehouses') {
      userForm[key] = data[key].map(record => {
        return { ...record, id: record.warehouse_id };
      });
    } else {
      userForm[key] = data[key];
    }
  }
  return { ...state, userForm };
}

function selectMenuModule(state, data) {
  return {
    ...state,
    rolePermissions: state.rolePermissions.map((module, moduleIndex) => {
      if (moduleIndex === data.moduleIndex) {
        return {
          ...module,
          menus: module.menus.map((menu, menuIndex) => {
            if (menuIndex === data.menuIndex) {
              return { ...menu, selected: true };
            }
            return { ...menu, selected: false };
          }),
        };
      } else {
        return module;
      }
    }),
  };
}

function togglePermission(state, data) {
  return {
    ...state,
    rolePermissions: state.rolePermissions.map((module, moduleIndex) => {
      if (moduleIndex === data.module_index) {
        console.log('caught module index');
        return {
          ...module,
          menus: module.menus.map((menu, menuIndex) => {
            if (menuIndex === data.menu_index) {
              console.log('caught menuIndex');
              return {
                ...menu,
                permissions: menu.permissions.map(permission => {
                  if (permission.access_permission === data.access_permission) {
                    console.log('permission causgt');
                    return {
                      ...permission,
                      is_assigned: !permission.is_assigned,
                    };
                  } else {
                    return { ...permission };
                  }
                }),
              };
            } else {
              return { ...menu };
            }
          }),
        };
      } else {
        return module;
      }
    }),
  };
}
