import logo from './../assets/img/logo.png';
import PropTypes from 'prop-types';
import React, { useState, setState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      forgotPassword: '',
    };
  }

  forgotPassword = event => {
    this.props.onChangeAuthState(STATE_FORGOT);
  };

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  get isForgotPassword() {
    return this.props.authState === STATE_FORGOT;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    if (this.props.authState == "LOGIN") {
      if (!this.state.username || !this.state.password) {
        alert('Please fill required fields');
        return;
      }
      this.props.onLoginClick(this.state.username, this.state.password);
      event.preventDefault();
    }
    else if (this.props.authState == "FORGOT") {
      alert(this.state.forgotPassword);
      this.props.onForgotPasswordClick(this.state.forgotPassword);
      event.preventDefault();
    }

  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  renderButtonText() {
    const { buttonText, isWorking } = this.props;

    if (!buttonText && this.isLogin) {
      return (<> {isWorking && <span className="k-icon  k-i-loading"></span>} Login <i className="ri-arrow-right-line"></i></>);
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    if (!buttonText && this.isForgotPassword) {
      return 'Reset';
    }

    return buttonText;
  }

  render() {

    const {
      showLogo,
      usernameLabel,
      forgotPasswordLable,
      forgotPasswordInputProps,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
      loading

    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="logo-text pb-4">
            <img
              src={logo}
              className="rounded"
              style={{ height: 60, cursor: 'pointer', marginBottom: 20 }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        {this.isLogin &&
          (<> <FormGroup>
            <Input {...usernameInputProps} name="username" value={this.state.username} onChange={this.handleChange} />
            <Label for={usernameLabel}>{usernameLabel}</Label>
          </FormGroup>
            <FormGroup>
              <Input {...passwordInputProps} name="password" value={this.state.password} onChange={this.handleChange} />
              <Label for={passwordLabel}>{passwordLabel}</Label>
            </FormGroup></>)
        }

        {/* {this.isSignup && (
          <FormGroup>
            <Input {...confirmPasswordInputProps} />
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
          </FormGroup>
        )} */}
        {this.isForgotPassword && (
          <><div>Forgot Password</div>
            <div>Enter your email address</div>
            <FormGroup>
              <Input {...forgotPasswordInputProps} name="forgotPassword" value={this.state.forgotPassword} onChange={this.handleChange} />
              <Label for={forgotPasswordLable}>{forgotPasswordLable}</Label>
            </FormGroup></>
        )
        }

        <div className="d-flex align-items-center justify-content-between">
          {this.isLogin &&
            <><a href="#" className="forgot-pass" onClick={this.forgotPassword}>Forgot Password?</a>
              <Button
                type='submit'
                disabled={this.props.isWorking}
                size="lg"
                className="login-btn border-0"
                block>
                {this.renderButtonText()}
              </Button></>
          }
          {this.isForgotPassword &&
            <Button
              type='submit'
              disabled={this.props.isWorking}
              size="lg"
              className="login-btn border-0"
              block>
              {this.renderButtonText()}
            </Button>
          }

        </div>
        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';
export const STATE_FORGOT = 'FORGOT';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP, STATE_FORGOT]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  onLoginClick: PropTypes.func,
  onForgotPasswordClick: PropTypes.func,
  forgotPasswordLable:PropTypes.string,
  forgotPasswordInputProps: PropTypes.object
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: ' ',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: ' ',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: ' ',
  },
  forgotPasswordLable: 'Email Address',
  forgotPasswordInputProps: {
    type: 'forgotPassword',
    placeholder: ' ',
  },
  onLogoClick: () => { },
  onLoginClick: () => { },
  onForgotPasswordClick:() =>{ }
};

export default AuthForm;
