import React, { useState, useEffect, useCallback } from 'react';
import TableWrapper from './TableWrapper';
import { Grid, GridToolbar, GridColumn } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import TableHeader from './TableHeader';
import EditButton from '../ActionButton/EditButton';
import DeleteButton from '../ActionButton/DeleteButton';
import ViewButton from '../ActionButton/ViewButton';
import AddButton from '../ActionButton/AddButton';
import PrintButton from '../ActionButton/PrintButton';
import NotesButton from '../ActionButton/NotesButton';
import ActionButton from '../ActionButton/ActionButton';
import AddFactoryButton from '../ActionButton/AddFactory';
import ColorButton from '../ActionButton/ColorButton';
import NewButton from '../ActionButton/NewButton';
import ActiveUserButton from '../ActionButton/ActiveUserButton';
import CostCommunicationButton from '../ActionButton/CostCommunicationButton';
import ImageCell from '../Shared/ImageCell';
import EmailButton from '../ActionButton/EmailButton';
import ResetPasswordButton from '../ActionButton/ResetPasswordButton';
import { history } from '../../utils/history';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import ChargebackButton from '../ActionButton/ChargebackButton';
const DataTable = props => {
  const { dashboardStatusName = {} } = useSelector(state => state.cost_request);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [paginate, setPaginate] = useState({ skip: 0, take: 10 });
  let isCosting = history.location.pathname.includes('/costing');
  let isUserManagement = history.location.pathname.includes('/userManagement');
  const [costStatusName, setCostStatusName] = useState(
    dashboardStatusName.status || 'Search by Status',
  );
  const [usersStatus, setUsersStatus] = useState('all');
  /// Start :check box configuration ///
  let lastSelectedIndex = 0;
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (props.data) {
      setGridData(
        props.data.map(dataItem =>
          Object.assign({ selected: false }, dataItem),
        ),
      );
    }
  }, [props.data]);

  useEffect(() => {
    if (isUserManagement) {
      setPaginate({ skip: 0, take: 20 });
    }
  }, [history.location.pathname]);

  function handleUsersStatus(status) {
    setUsersStatus(status);
    setPaginate({ skip: 0, take: 20 });
  }

  const selectionChange = event => {
    const data = gridData.map(item => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setGridData(data);
    props.clickedCheckBox([event.dataItem]);
  };

  // not use methode
  const rowClick = event => {
    let last = lastSelectedIndex;
    const data = [...gridData];
    const current = data.findIndex(dataItem => dataItem === event.dataItem);
    console.log('[rowclick]' , event);

    if (!event.nativeEvent.shiftKey) {
      lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      data.forEach(item => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      data[i].selected = select;
    }
    setGridData(data);
  };

  const headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    const data = gridData.map(item => {
      item.selected = checked;
      return item;
    });
    setGridData(data);
    props.clickedCheckBox(gridData);
  };

  const expandChange = event => {
    event.dataItem.expanded = !event.dataItem.expanded;
    forceUpdate();
  };

  const pageChange = event => {
    setPaginate({
      skip: event.page.skip,
      take: event.page.take,
    });
    props.onPageChange({ skip: event.page.skip, take: event.page.take });
  };

  // Right Drawer
  const drawerWidth = 540;
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  // Draw grids
  const grids = props.columns.map((grid, i) => {
    const { buttons, ...gridProps } = grid;

    if (buttons) {
      gridProps.cell = cellProps => {
        return (
          <td className="action-btn__td">
            <div className="action-btn__wrap">
              {buttons.includes('add-new') ? (
                <NewButton clicked={props.clickedAddNew} />
              ) : null}

              {/* Edit button */}
              {buttons.includes('edit') ? (
                <EditButton
                  clicked={() => props.clickedEdit(cellProps.dataItem)}
                />
              ) : null}
              {/* Delete button */}
              {buttons.includes('delete') ? (
                history.location.pathname.includes('userManagement') &&
                cellProps.dataItem.deleted_at !== null ? (
                  <DeleteButton
                    disabled={true}
                    delete={() =>
                      props.delete(cellProps.dataItem[gridProps.field])
                    }
                  />
                ) : (
                  <DeleteButton
                    delete={() =>
                      props.delete(cellProps.dataItem[gridProps.field])
                    }
                  />
                )
              ) : null}

              {buttons.includes('reset-password') ? (
                <ResetPasswordButton
                  resetPassword={() => props.resetPassword(cellProps.dataItem)}
                />
              ) : null}
              {buttons.includes('view') ? (
                <ViewButton clicked={props.clickedView} {...cellProps} />
              ) : null}

              {buttons.includes('color') ? (
                <ColorButton field={gridProps.field} {...cellProps} />
              ) : null}

              {buttons.includes('active-user') ? (
                <ActiveUserButton field={gridProps.field} {...cellProps} />
              ) : null}
              {buttons.includes('add') || buttons.includes('clone') ? (
                <AddButton
                  clicked={props.clickedAdd}
                  field={gridProps.field}
                  {...cellProps}
                  buttons={buttons}
                />
              ) : null}

              {buttons.includes('print') ? (
                <PrintButton
                  clicked={props.clickedPrint}
                  field={gridProps.field}
                  {...cellProps}
                />
              ) : null}

              {buttons.includes('info') ? (
                <ActionButton
                  clicked={props.clickedInfo}
                  field={gridProps.field}
                  {...cellProps}
                />
              ) : null}
              {buttons.includes('add_factory') ? (
                <AddFactoryButton
                  clicked={props.clickedAddFactory}
                  field={gridProps.field}
                  {...cellProps}
                />
              ) : null}

              {buttons.includes('send_email') ? (
                <EmailButton
                  clicked={() => props.clickedSendEmail(cellProps.dataItem)}
                  field={gridProps.field}
                  {...cellProps}
                />
              ) : null}

              {buttons.includes('cost_communication') ? (
                <CostCommunicationButton
                  clicked={props.clickedCostCommunication}
                  field={gridProps.field}
                  {...cellProps}
                />
              ) : null}
              {buttons.includes('notes') ? (
                <NotesButton
                  clicked={props.clickedNotes}
                  field={gridProps.field}
                  {...cellProps}
                />
              ) : null}
              {buttons.includes('charge_back') ? (
                <ChargebackButton 
                clicked={() => props.clickedChargeback(cellProps.dataItem)}
                />
              ) : null}
              {/* example button - remove this after implementation */}
            </div>
          </td>
        );
      };
    }

    return (
      (gridProps.hidden === true ? false : true) && (
        <GridColumn key={i} {...gridProps}></GridColumn>
      )
    );
  });

  const imageCell = cellProps => (
    <ImageCell {...cellProps} location={props.imageLocation} />
  );
  //for filter costing

  function costingSearchData() {
    try {
      return (
        [
          ...new Set(
            gridData
              .filter(item => {
                return item.status.name || '';
              })
              .map(item => {
                return item.status.name || '';
              }),
          ),
        ] || []
      );
    } catch (error) {
      return [];
    }
  }
  let costingStatusNames = costingSearchData();

  let costingFilterByStatusNameData =
    costStatusName !== 'Search by Status'
      ? gridData.filter(item => {
          return (
            item.status != null &&
            item.status !== undefined &&
            item.status['name'] === costStatusName
          );
        })
      : gridData;
  // console.log(costingSearchData);
  let activeUsers = gridData.filter(item => item.deleted_at === null);
  let inactiveUsers = gridData.filter(item => item.deleted_at !== null);
  let filterActiveUsers = () => {
    if (usersStatus === 'all') return gridData;
    if (usersStatus === 'active') return activeUsers;
    if (usersStatus === 'inactive') return inactiveUsers;
  };
  let loadAllUsers = filterActiveUsers();
  let handleCostingStatusChange = e => {
    setCostStatusName(e.target.value);
  };

  return (
    <>
    {/* {console.log('[testlog]', gridData)} */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={openDrawer}
        classes={{ paper: 'drawer-inner' }}
      >
        <div className="">
          <div className="drawer-inner__header">
            <h2>Edit Shipping Entry Log ID: 033100</h2>
            <button onClick={handleDrawerClose}>
              <i className="ri-close-line"></i>
            </button>
          </div>
          <div className="drawer-inner__content">Form Goes Here</div>
        </div>
      </Drawer>
      <TableWrapper header={props.header} loading={props.loading}>
        <Grid
          sortable
          selectedField="selected"
          onSelectionChange={selectionChange}
          onHeaderSelectionChange={headerSelectionChange}
          onRowClick={rowClick}
          detail={props.detailComponent}
          expandField={props.addDetail ? 'expanded' : ''}
          onExpandChange={expandChange}
          data={
            isCosting === true
              ? costingFilterByStatusNameData
              : gridData || isUserManagement === true
              ? loadAllUsers
              : gridData
          }
          skip={paginate.skip}
          take={paginate.take}
          total={
            isCosting === true
              ? costingFilterByStatusNameData.length
              : props.total || isUserManagement === true
              ? props.total
              : props.total
          }
          pageable={true}
          onPageChange={pageChange}
        >
          <GridToolbar>
            <TableHeader
              handleSearch={props.handleSearch}
              clicked={props.clickedNew}
              url={props.url}
              title={props.title}
              handleUsersStatus={handleUsersStatus}
              isCosting={isCosting}
              costing_data={{
                data: costingStatusNames,
                value: costStatusName,
                handleCostingStatusChange: handleCostingStatusChange,
              }}
              {...props}
            ></TableHeader>
          </GridToolbar>
          {grids.slice(0, 1)}
          {props.addCheckbox && (
            <GridColumn
              field="selected"
              width="50px"
              headerSelectionValue={
                gridData.findIndex(dataItem => dataItem.selected === false) ===
                -1
              }
            ></GridColumn>
          )}
          {props.showImageColumn === true && (
            <GridColumn
              field="image"
              width="100px"
              cell={imageCell}
            ></GridColumn>
          )}
          {grids.slice(1, grids.length)}
        </Grid>
      </TableWrapper>
    </>
  );
};

export default DataTable;
