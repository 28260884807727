import * as types from '../../actions/colors/actionTypes';
import initialState from '../initialState';

export default function associatePackReducer(
  state = initialState.colorList,
  action,
) {
  switch (action.type) {
    case types.LOAD_ALL_COLORS:
      return {
        ...state,
        colorList: [...action.data.data],
      };
    default:
      return state;
  }
}
