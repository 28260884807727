import * as types from './actionTypes';
import * as authApi from '../../../api/authApi';
import {
  beginApiCall,
  apiCallError,
  apiCallSuccess,
} from '../apiStatusActions';
import { history } from '../../../utils/history';
import { addNotification } from '../notification/notificationActions';
import { getAllUserPermissions } from '../../../api/userPermissions';

export function loginSuccess(data) {
  return { type: types.LOGIN_SUCCESS, data };
}

export function login(payload) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .login(payload)
      .then(result => {
        localStorage.setItem('auth', JSON.stringify(result));
        localStorage.setItem('token', result.token);
        localStorage.setItem('display_name', result.display_name);
        localStorage.setItem('roles', result.roles);
        localStorage.setItem('username', result.username);
        localStorage.setItem('email', result.email);
        localStorage.setItem('id', result.id);

        getAllUserPermissions().then(result => {
          let data = {};
          if (result.hasOwnProperty('data.roles[0].permissions') === false) {
            data.permissions = result.data.roles[0].permissions;
          } else {
            data.permissions = {};
          }
          dispatch({ type: types.LOAD_USER_PERMISSIONS, data });
          localStorage.setItem('permissions', JSON.stringify(data));
          dispatch(apiCallSuccess());

          if (result.is_default_password === 1 && result.username !== 'webadmin') {
            history.push('/reset');
          } else {
            history.push('/');
          }
        }).catch(error => {
          dispatch(addNotification(error, 'error'));
          dispatch(apiCallError(error));
        });
        return result;
      })
      .catch(() => {
        dispatch(addNotification('User credentials are incorrect', 'error'));
        dispatch(apiCallError());
        return Promise.reject(false);
      });
  };
}

export function resetPassword(payload) {
  return dispatch => {
    dispatch(beginApiCall());
    return authApi
      .resetPassword(payload)
      .then(result => {
        console.log(result);
        dispatch(apiCallError);
        if (history.location.pathname.includes('userManagement')) {
          dispatch(addNotification(result, 'success'));
          history.push('/userManagement/');
        } else {
          history.push('/login');
          localStorage.removeItem('token');
          localStorage.removeItem('email');
          localStorage.removeItem('id');
          localStorage.removeItem('username');
        }
      })
      .catch(error => {
        dispatch(addNotification(error.message, 'error'));
        dispatch(apiCallError());
        return Promise.reject(true);
      });
  };
}
