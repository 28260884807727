import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
} from 'reactstrap';
import { GithubPicker } from 'react-color';
import * as Yup from 'yup';
import { Formik } from 'formik';

const RoleForm = props => {
  const {
    handleSubmit,
    roleForm,
    toggleColorPalette,
    colorPalette,
    toggleDialog,
    dispatch,
    handleColor,
    selectedColor,
  } = props;
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: roleForm.name || '',
          slug: roleForm.slug || '',
          description: roleForm.description || '',
          rank: roleForm.rank || '',
          role_color: roleForm.role_color || '',
        }}
        onSubmit={async values => {
          handleSubmit(values);
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Display Name is Required'),
          slug: Yup.string().required(' Role Slug is Required'),
          description: Yup.string().required('Description is Required'),
          rank: Yup.string().required('Rank Required'),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            clearUserRoleForm,
            dispatch,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="slug">Role Slug</Label>
                    <Input
                      name="slug"
                      id="slug"
                      value={values.slug}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      title="Please do not use the white space"
                      pattern="[^\s]+"
                      disabled={roleForm.create === 'yes' ? false : true}
                      className={
                        errors.slug && touched.slug ? 'is-invalid' : ''
                      }
                    />
                    {errors.slug && touched.slug && (
                      <FormFeedback> {errors.slug}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="name">Display Name</Label>
                    <Input
                      name="name"
                      id="name"
                      value={values.name}
                      // title="Please do not use the white space"
                      // pattern="[^\s]+"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? 'is-invalid' : ''
                      }
                    />
                    {errors.name && touched.name && (
                      <FormFeedback> {errors.name}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <Label for="'description'">Description</Label>
                    <Input
                      name="description"
                      id="description"
                      type="textarea"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? 'is-invalid'
                          : ''
                      }
                    />
                    {errors.description && touched.description && (
                      <FormFeedback> {errors.description}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="rank">Rank</Label>
                    <Input
                      name="rank"
                      id="rank"
                      min={0}
                      value={values.rank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={'number'}
                      className={
                        errors.rank && touched.rank ? 'is-invalid' : ''
                      }
                    />
                    {errors.rank && touched.rank && (
                      <FormFeedback> {errors.rank}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Button
                      type={'button'}
                      style={{
                        marginTop: 25,
                        marginBottom: 10,
                        backgroundColor: selectedColor || roleForm.role_color,
                        color: '#bcc7bf',
                      }}
                      onClick={() => {
                        toggleColorPalette(!colorPalette);
                      }}
                    >
                      Color
                    </Button>
                    {colorPalette && (
                      <GithubPicker
                        onChangeComplete={color => {
                          // dispatch(
                          //   addUserRoleColor( color.hex),
                          // )
                          handleColor(color);
                          toggleColorPalette(!colorPalette);
                        }}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button
                  type="button"
                  className="btn btn-lg"
                  style={{ marginLeft: 13 }}
                  onClick={toggleDialog}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-lg btn-primary submit-button"
                  style={{ marginLeft: 13 }}
                  disabled={isSubmitting}
                >
                  {roleForm.create === 'yes' ? 'Save' : 'Update'}
                </Button>{' '}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default RoleForm;
