export function getGridColumns() {
  return [
    {
      field: 'group_by',
      width: '250px',
      title: 'Group',

      show: true,
    },

    // {
    //   field: 'imagesString',
    //   width: '250px',
    //   title: 'Images',

    //   show: true,
    // },
    {
      field: 'fabric_name',
      width: '250px',
      title: 'Fabric',

      show: true,
    },
    {
      field: 'factory_name',
      width: '250px',
      title: 'Factory',

      show: true,
    },
    {
      field: 'country_name',
      width: '250px',
      title: 'COO',

      show: true,
    },
    {
      field: 'body_option_name',
      width: '250px',
      title: 'Body Option',

      show: true,
    },
    {
      field: 'yield',
      width: '100px',
      title: 'Yield',

      show: true,
    },
    {
      field: 'hst_details.material_id',
      width: '100px',
      title: 'Material',

      show: true,
    },
    {
      field: 'hst_details.how_to_make_id',
      width: '100px',
      title: 'How To Make',

      show: true,
    },
    {
      field: 'hst_details.hst_code',
      width: '100px',
      title: 'HST Code',

      show: true,
    },
    {
      field: 'hst_details.cat',
      width: '250px',
      title: 'CAT',

      show: true,
    },
    {
      field: 'duty_rate',
      width: '100px',
      title: 'Duty Rate',

      show: true,
    },
    {
      field: 'additional_tax',
      width: '100px',
      title: 'Tariff rate',

      show: true,
    },
    {
      field: 'is_duty_free',
      width: '150px',
      title: 'Duty Free',

      show: true,
    },
    {
      field: 'freight',
      width: '150px',
      title: 'Freight',

      show: true,
    },
    {
      field: 'additional_cost',
      width: '150px',
      title: 'Additional Cost',

      show: true,
    },
    {
      field: 'factory_target',
      width: '150px',
      title: 'Factory Target',

      show: true,
    },
    {
      field: 'fob',
      width: '150px',
      title: 'FOB',

      show: true,
    },
    {
      field: 'ldp',
      width: '150px',
      title: 'LDP',

      show: true,
    },
    {
      field: 'factory_delivery',
      width: '250px',
      title: 'Factory Delivery',

      show: true,
    },
    {
      field: 'transmit_time',
      width: '100px',
      title: 'Transmit Time',

      show: true,
    },
    {
      field: 'in_ware_house_date',
      width: '250px',
      title: 'In Warehouse Date',

      show: true,
    },
    {
      field: 'factory_comment',
      width: '250px',
      title: 'Factory Comment',

      show: true,
    },
    {
      field: 'fabric_information',
      width: '250px',
      title: 'Fabric Information',

      show: true,
    },
    {
      field: 'print_remarks',
      width: '250px',
      title: 'Print Remarks',

      show: true,
    },
    {
      field: 'print_style',
      width: '250px',
      title: 'Print Style',

      show: true,
    },
    {
      field: 'hang_tag',
      width: '150px',
      title: 'Hand Tag',

      show: true,
    },
    {
      field: 'label',
      width: '150px',
      title: 'Label',

      show: true,
    },
    {
      field: 'offered_sell',
      width: '250px',
      title: 'Offered Sell',

      show: true,
    },
    {
      field: 'customer_sell',
      width: '250px',
      title: 'Customer Sell',

      show: true,
    },
    {
      field: 'dilution_rate',
      width: '250px',
      title: 'Dilution Rate',

      show: true,
    },
    {
      field: 'offer_net_sell',
      width: '250px',
      title: 'Offer Net Sell',

      show: true,
    },
    {
      field: 'customer_net_sell',
      width: '250px',
      title: 'Customer Net Sell',

      show: true,
    },
    {
      field: 'gross_margin_bn_offered_sell',
      width: '250px',
      title: 'GrossMargin BN Offered Sell',

      show: true,
    },
    {
      field: 'net_margin_bn_offered_sell',
      width: '250px',
      title: 'NetMargin BN Offered Sell',

      show: true,
    },

    {
      field: 'gross_margin_bn_customer_sell',
      width: '250px',
      title: 'Gross Margin BN Customer Sell',

      show: true,
    },

    {
      field: 'net_margin_bn_customer_sell',
      width: '250px',
      title: 'Net Margin BN Customer Sell',

      show: true,
    },
    {
      field: 'customer_start_date',
      width: '250px',
      title: 'Customer Start Date',

      show: true,
    },
    {
      field: 'customer_cancel_date',
      width: '250px',
      title: 'Customer Cancel Date',

      show: true,
    },
    {
      field: 'customer_indc',
      width: '250px',
      title: 'Customer INDC',

      show: true,
    },
    {
      field: 'lead_time',
      width: '250px',
      title: 'Lead Time',

      show: true,
    },
    {
      field: 'earliest_customer_start_date',
      width: '250px',
      title: 'Earliest Customer Start Date',

      show: true,
    },
    {
      field: 'earliest_customer_cancel_date',
      width: '250px',
      title: 'Earliest Customer Cancel Date',

      show: true,
    },
    {
      field: 'earliest_customer_indc',
      width: '250px',
      title: 'Earliest Customer INDC',

      show: true,
    },
    {
      field: 'customer_price',
      width: '250px',
      title: 'Customer Price',

      show: true,
    },
    {
      field: 'retail_price',
      width: '250px',
      title: 'Retail Price',

      show: true,
    },
    {
      field: 'target_price',
      width: '250px',
      title: 'Target Price',

      show: true,
    },
  ];
}
