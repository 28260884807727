import * as types from "../../actions/actionTypes";
import initialState from "../initialState";
import {cloneDeep} from "lodash";

export default function fabricReducer(state = initialState.fabrics, action) {
    switch (action.type) {
        case types.LOAD_FABRIC_ALL_SUCCESS:
            return action.data;
        case types.LOAD_FABRIC_SUCCESS:
            return action.data;
        case types.SAVED_FABRIC:
            const cloneState = cloneDeep(state)
            cloneState.push(action.data)
            return cloneState
        default:
            return state;
    }
}
