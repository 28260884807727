import React from 'react';
import { useEffect, useState, useRef } from 'react';

export const OutSideClickHandler = props => {
  // const [wrapperRef, setWrapperRef] = useState(null);
  const ref = useRef();

  useEffect(() => {
    document.body.addEventListener('mousedown', handleClickOutside, {
      capture: true,
    });
    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      });
    };
  }, [props.status]);

  // const _setWrapperRef = node => {
  //   setWrapperRef(node);
  // };

  const handleClickOutside = (event: any) => {
    if (!ref.current.contains(event.target)) {
      //right panel clicked
      props.onOutsideClick();
    }
  };

  const { style = {}, children } = props;
  return (
    <div style={style} ref={ref}>
      {children}
    </div>
  );
};
