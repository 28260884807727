export const LOAD_RECEIVINGLOG_ALL_SUCCESS = 'LOAD_RECEIVINGLOG_ALL_SUCCESS';
export const LOAD_LOGSTATUS_ALL_SUCCESS = 'LOAD_LOGSTATUS_ALL_SUCCESS';
export const LOAD_WAREHOUSES_ALL_SUCCESS = 'LOAD_WAREHOUSES_ALL_SUCCESS';
export const LOAD_RECEIVINGLOG_SUCCESS = 'LOAD_RECEIVINGLOG_SUCCESS';
export const LOAD_RECEIVINGLOG_DOCUMENTS_ALL_SUCCESS =
  'LOAD_RECEIVINGLOG_DOCUMENTS_ALL_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const LOAD_RECEIVING_LOG_NOTES_ALL_SUCCESS =
  'LOAD_RECEIVING_LOG_NOTES_ALL_SUCCESS';
export const LOAD_RECEIVING_LOG_MERGED_NOTES_ALL_SUCCESS =
  'LOAD_RECEIVING_LOG_MERGED_NOTES_ALL_SUCCESS';
export const LOAD_RECEIVING_TAGS = 'LOAD_RECEIVING_TAGS';
export const LOAD_RECEIVING_LOG_MAIL_TEMPLATE =
  'LOAD_RECEIVING_LOG_MAIL_TEMPLATE';
export const LOAD_RECEIVING_LOG_SUMMARY = 'LOAD_RECEIVING_LOG_SUMMARY';
