import * as types from '../../actions/actionTypes';
import initialState from '../initialState';

export default function productReducer(state = initialState.products, action) {
    switch (action.type) {
        case types.LOAD_AMT_ITEMS_SUCCESS:
            return { ...state, amtItems: action.data };
        case types.LOAD_COMPANIES_SUCCESS:
            return { ...state, companies: action.data };
        case types.LOAD_DIVISIONS_SUCCESS:
            return { ...state, divisions: action.data };
        case types.LOAD_SEASONS_SUCCESS:
            return { ...state, seasons: action.data };
        case types.LOAD_VENDORS_SUCCESS:
            return { ...state, vendors: action.data };
        case types.GET_PRODUCT_DETAILS_SUCCESS:
            return { ...state, productDetails: action.data };
        case types.GET_SCANNED_HISTORY_SUCCESS:
            return { ...state, scanHistory: action.data };
        case types.GET_SCANNED_ITEMS_SUCCESS:
            return { ...state, scannedItems: action.data };
        case types.GENERATE_REPORT_SUCCESS:
            return { ...state, report: action.data };
        case types.GET_LATEST_REPORT_SUCCESS:
            return { ...state, latestReport: action.data };
        default:
            return state;
    }
}