import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function brandReducer(state = initialState.brands, action) {
  switch (action.type) {
    case types.LOAD_BRAND_ALL_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
