import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function MaterialReducer(state = initialState.materials, action) {

  switch (action.type) {
    case types.LOAD_MATERIALS_ALL_SUCCESS:
      return action.data;  
    default:
      return state;
  }
}
