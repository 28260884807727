import * as types from "../../actions/orderType/actionTypes";
import initialState from "../initialState";

export default function locationReducer(state = initialState.order_types, action) {
    if (action.type === types.LOAD_ALL_ORDER_TYPE) {
        return action.data;
    } else {
        return state;
    }
}
