import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const NotesButton = props => {
  return (
    <Tooltip title={props.title || 'Notes'} placement={'top'}>
      <button
        className="action-btn action-btn--small mr-2 k-button"
        onClick={() => {
          props.clicked(props.dataItem);
        }}
        style={{ position: 'relative' }}
      >
        <i className="ri-sticky-note-fill"></i>
        <div className="badge-wrap" style={{ left: '50%' }}>
          {props.dataItem.sn_note_count > 0 && (<div className="badge badge--send">
            {props.dataItem.sn_note_count}
          </div>)}
          {props.dataItem.sn_note_count > 0 && (
            <div className="badge badge--recieve">
              {props.dataItem.wh_note_count}
            </div>
          )}

        </div>
      </button>
    </Tooltip>
  );
};

export default NotesButton;
