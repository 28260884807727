import * as types from '../../actions/actionTypes';
import initialState from '../initialState';

export default function vendorsReducer(
  state = initialState.vendors, 
  action
  ) {
  switch (action.type) {
    case types.LOAD_VENDORS_ALL_SUCCESS:
      return {...state, allVendors: action.data.data};
    case types.LOAD_VENDOR_SUCCESS:
      return {...state, selectedVendor: action.data};
    default:
      return state;
  }
}
