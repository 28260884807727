import React, { Fragment } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { history } from '../../utils/history';
const TableHeader = props => {
  const username = localStorage.getItem('display_name');

  const isAdmin = () => username === 'Developers Admin';
  let boolValue = null;

  if (props.tHeader !== undefined && props.tHeader !== null) {
    if (
      props.tHeader.dropdownlists !== undefined &&
      props.tHeader.dropdownlists !== null
    ) {
      let index = (props.tHeader.validateElement || {}).dropdown;
      boolValue =
        index != undefined
          ? props.tHeader.dropdownlists[index].validateAddNew
          : false;
    }

    /// by create if condition continue can validate here....
  }

  const headerButtons = () => {
    if (!props.isFacUser) {
      const btns = get(props, 'tHeader.buttons');

      if (btns) {
        return btns.map(bt => {
          return (
            <Fragment key={bt.name}>
              <button
                type="button"
                className="btn btn-primary d-flex align-items-center"
                onClick={bt.onClick}
                disabled={bt.disabled ? bt.disabled : false}
              >
                <i className="ri-add-circle-line mr-2" />
                {bt.name}
              </button>
            </Fragment>
          );
        });
      }
    }
  };

  const headerTextBoxes = () => {
    if (!props.isFacUser) {
      const textBoxes = get(props, 'tHeader.textBoxes');
      if (textBoxes) {
        return textBoxes.map(tb => {
          return (
            <Input
              className="form-control"
              onChange={tb.handleChange}
              placeholder={tb.placeholder}
              name={tb.name}
              id={tb.id}
            />
          );
        });
      }
    }
  };

  const headerDropdowns = () => {
    if (!props.isFacUser) {
      const dropdownlists = get(props, 'tHeader.dropdownlists');
      if (dropdownlists) {
        return dropdownlists.map(element => {
          return (
            <div key={element.id} className="mb-3 col-lg-3">
              {/* <label>{element.name} </label> */}
              <DropDownList
                data={element.dataSource}
                textField={element.textField}
                dataItemKey={element.dataItemKey}
                name={element.name}
                id={element.id}
                value={element.value}
                onChange={element.handleChange}
                className="form-control"
              />
            </div>
          );
        });
      } else {
        return null;
      }
    }
  };

  const costingFilter = () => {
    const { data, value, handleCostingStatusChange } = props.costing_data;

    return (
      <div
        style={{
          width: '200px',
        }}
      >
        <DropDownList
          data={data}
          value={value}
          onChange={e => handleCostingStatusChange(e)}
          defaultItem="Search by Status"
          className="form-control"
        />
      </div>
    );
  };
  return (
    <>
      {props.tHeader &&
        ((props.tHeader.textBoxes && props.tHeader.textBoxes.length > 0) ||
          (props.tHeader.dropdownlists &&
            props.tHeader.dropdownlists.length > 0)) && (
          <div
            className="row mb-5 pb-3"
            style={{ borderBottom: '1px solid #eeeeee' }}
          >
            {headerDropdowns()}
            {headerTextBoxes()}
          </div>
        )}

      <div className="justify-content-between d-flex">
        {(props.showSearch === false ? false : true) && (
          <div className="grid-search-wrap">
            <Input
              className="form-control grid-search"
              onChange={props.handleSearch}
              placeholder={`Search ${props.title}`}
              name="query"
            />
          </div>
        )}

        {history.location.pathname.includes('userManagement') && (
          <div className="d-flex align-items-center">
            <strong className="mr-4">Legend:</strong>
            <div className="legend-table">
              <span className="bg-success circle circle--xs mr-2"></span>
              <Button
                onClick={() => props.handleUsersStatus('active')}
                color="primary"
              >
                Active Users
              </Button>
            </div>

            <div className="legend-table">
              <span className="bg-danger circle circle--xs mr-2"></span>
              <Button
                onClick={() => props.handleUsersStatus('inactive')}
                color="primary"
              >
                Inactive Users
              </Button>
            </div>

            <div className="legend-table">
              <Button
                onClick={() => props.handleUsersStatus('all')}
                color="primary"
              >
                All Users
              </Button>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center btn-grouped">
          {headerButtons()}
          {props.isCosting &&
            props.tHeader.buttons.map(bt => {
              return (
                <Fragment key={bt.name}>
                  <button
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    onClick={bt.onClick}
                    disabled={bt.disabled ? bt.disabled : false}
                  >
                    <i className="ri-add-circle-line mr-2" />
                    {bt.name}
                  </button>
                </Fragment>
              );
            })}

          {isAdmin() === true || props.isPermissions === true
            ? (props.showAddNew == false ? false : true) && (
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  onClick={props.clicked}
                  disabled={boolValue}
                >
                  <i className="ri-add-fill mr-2" />
                  New {props.title}
                </button>
              )
            : null}
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          marginTop: '-43px',
          marginLeft: '270px',
        }}
      >
        {props.isCosting && costingFilter()}
      </div>
    </>
  );
};
export default TableHeader;
