import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function hstCodeReducer(state = initialState.hst_codes, action) {
  switch (action.type) {
    case types.LOAD_HSTCODE_ALL_SUCCESS:
      return action.data;
    case types.LOAD_HSTCODE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
