import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function divisionReducer(state = initialState.divisions, action) {
  switch (action.type) {
    case types.LOAD_DIVISION_ALL_SUCCESS:
      //return action.data;
      return { ...state, data: action.data };
    case types.LOAD_DIVISION_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
