import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleDialog: false
        };
    }

    toggleDialog = () => {
        this.setState({
            visibleDialog: !this.state.visibleDialog
        });
    }
    render() {
        return (
            <div>
                {this.state.visibleDialog && <Dialog title={this.props.title} onClose={this.toggleDialog}>
                    <p style={{ marginLeft: "25px" }}>{this.props.message}</p>
                    {this.props.addTextBox === true && <input type="text" onChange={this.props.onTextChange}
                        placeholder="Batch Name" value={this.props.textBoxValue} className="form-control" style={{ marginLeft: "25px", width: "300px" }} />}
                    <DialogActionsBar>
                        <button className="btn btn-default" onClick={this.toggleDialog} style={{ margin: "2px" }}>No</button>
                        <button className="btn btn-primary" disabled={this.props.addTextBox === true && this.props.textBoxValue === ""}
                            onClick={() => { this.toggleDialog(); this.props.confirmed() }} style={{ margin: "2px" }}>Yes</button>
                    </DialogActionsBar>
                </Dialog>}
            </div>
        );
    }
}
export default ConfirmationModal;