import * as types from '../../actions/actionTypes';

import initialState from '../initialState';

export default function customerReducer(
  state = initialState.customers,
  action,
) {
  switch (action.type) {
    case types.LOAD_CUSTOMER_ALL_SUCCESS:
      return { ...state, allCustomers: action.data };
    case types.LOAD_CUSTOMER_SUCCESS:
      return { ...state, selectedCustomer: action.data };
    case types.LOAD_ORDER_TYPES_SUCCESS:
      return { ...state, orderTypes: action.data };
    case types.RESET_CUSTOMER:
      return { ...state, selectedCustomer: [] };
    case types.ADD_CUSTOMER_FOR_QUNATITY:
      return {
        ...state,
        selected_customer_list: state.selected_customer_list
          ? [...state.selected_customer_list, action.data]
          : [action.data],
      };

    case types.REMOVE_CUSTOMER_FOR_QUNATITY:
      return {
        ...state,
        selected_customer_list: state.selected_customer_list.filter(
          i => i.customer_id !== action.id,
        ),
      };
    case types.REMOVE_ALL_CUSTOMERS:
      return {
        ...state,
        selected_customer_list: [],
      };
    default:
      return state;
  }
}
