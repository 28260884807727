export const LOAD_ALL_COST = 'LOAD_ALL_COST';
export const LOAD_ALL_COST_SUCCESS = 'LOAD_ALL_COST_SUCCESS';
export const LOAD_COST = 'LOAD_COST';
export const LOAD_COST_SUCCESS = 'LOAD_COST_SUCCESS';
export const SEARCH_COST = 'SEARCH_COST';
export const SEARCH_COST_SUCCESS = 'SEARCH_COST_SUCCESS';
export const SEARCH_COST_ERROR = 'SEARCH_COST_ERROR';
export const LOAD_COST_REQUEST = 'LOAD_COST_REQUEST';
export const SAVE_COST_SUCCESS = 'SAVE_COST_SUCCESS';
export const UPDATE_COST = 'UPDATE_COST';
export const ADD_ATTACHEMENT_IMAGE = 'ADD_ATTACHEMENT_IMAGE';
export const GET_CUSTOMER_REQUIREMENTS = 'GET_CUSTOMER_REQUIREMENTS';
export const UPDATE_CUSTOMER_COMMENTS = 'UPDATE_CUSTOMER_COMMENTS';
export const IS_EDIT_STATUS = 'IS_EDIT_STATUS';
export const ADD_DASHBOARD_STATUS_NAME = 'ADD_DASHBOARD_STATUS_NAME';
export const SELECT_TAB_INDEX = 'SELECT_TAB_INDEX';
export const GET_ALL_COST_COMMUNICATIONS = 'GET_ALL_COST_COMMUNICATIONS';
