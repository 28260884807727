import * as types from "../../actions/prePacks/actionTypes";
import initialState from "../initialState";

export default function prePackReducer(state = initialState.associate_packs, action) {
    switch (action.type) {
        case types.LOAD_ALL_PACKS:
            return action.data.data;
        default:
            return state;
    }
}
