export const LOAD_ALL_USERS = 'LOAD_ALL_USERS';
export const LOAD_ALL_ROLES = 'LOAD_ALL_ROLES';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const ERROR = 'ERROR';
export const SELECT_USER = 'SELECT_USER';
export const UPDATE_USER_FORM = 'UPDATE_USER_FORM';
export const UP_STEPPER = 'UP_STEPPER';
export const DOWN_STEPPER = 'DOWN_STEPPER';
export const CHANGE_ACTIVE_STEP = 'CHANGE_ACTIVE_STEP';
export const ADD_ROLE = 'ADD_ROLE';
export const REMOVE_ROLE = 'REMOVE_ROLE';
export const ADD_WAREHOUSE = 'ADD_WAREHOUSE';
export const SET_DEFAULT__WAREHOUSE = 'SET_DEFAULT__WAREHOUSE';
export const REMOVE_WAREHOUSE = 'REMOVE_WAREHOUSE_STATE';
export const CLEAR_WAREHOUSE_DEFAULTS = 'CLEAR_WAREHOUSE_DEFAULTS';
export const LOAD_ALL_SUPERVISORS = 'LOAD_ALL_SUPERVISORS';
export const LOAD_ALL_USER_TYPES = 'LOAD_ALL_USER_TYPES';
export const CLEAR_ALL_FORMS = 'CLEAR_ALL_FORMS';
export const LOAD_ALL_PERMISSIONS = 'LOAD_ALL_PERMISSIONS';
export const SELECT_MENU_IN_MODULE = 'SELECT_MENU_IN_MODULE';
export const TOGGLE_PERMISSION = 'TOGGLE_PERMISSION';
export const TOGGLE_PASSWORD = 'TOGGLE_PASSWORD';
export const ADD_ORDER_PERMISSION = 'ADD_ORDER_PERMISSION';
export const ADD_INVENTORY_ACCESS = 'ADD_INVENTORY_ACCESS';
export const ADD_ORDER_WITHOUT_INVENTORY = 'ADD_ORDER_WITHOUT_INVENTORY';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER_ROLE_FORM = 'CLEAR_USER_ROLE_FORM';
export const UPDATE_USER_ROLE_FORM = 'UPDATE_USER_ROLE_FORM';
export const SELECTED_USER_ROLE = 'SELECTED_USER_ROLE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const UPDATE_USER_ROLE = 'DELETE_USER_ROLE';
export const LOAD_ALL_LINE_SHEETS = 'LOAD_ALL_LINE_SHEETS';
export const ADD_USER_CAN_CREATE_LINESHEET = 'ADD_USER_CAN_CREATE_LINESHEET';
export const ADD_LINESHEET = 'ADD_LINESHEET';
export const REMOVE_LINESHEET = 'REMOVE_LINESHEET';
export const LOAD_ALL_USERNAMES = 'LOAD_ALL_USERNAMES';
export const LOAD_WAREHOUSES_ALL_SUCCESS = 'LOAD_WAREHOUSES_ALL_SUCCESS';
