import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useDispatch, useSelector } from 'react-redux';
import {
  fillCreateRoleFormAction,
  newRoleAction,
  clearUserRoleForm,
  getuserRoleAction,
  deleteUserRoleAction,
  updateUserRoleAction,
} from '../../../redux/actions/userManagement/userManagementAction';
import DrawTable from '../../../components/Table/DrawTable';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { history } from '../../../utils/history';
import RoleForm from './RoleForm';
import Loader from '../../../components/Shared/LoadinPanel';
// table columns

const UserRoles = () => {
  const isPermissions = useSelector(state =>
    state.role_based_access_controller !== null &&
    state.role_based_access_controller.permissions !== 'undifined' &&
    state.role_based_access_controller.hasOwnProperty('permissions') === true
      ? state.role_based_access_controller.permissions
      : {},
  );
  const username = localStorage.getItem('display_name');
  const isAdmin = () => username === 'Developers Admin';

  let actionButtons = [];

  if (isAdmin() === true || isPermissions['role.update'] === true) {
    actionButtons.push('edit');
  }
  if (isAdmin() === true || isPermissions['role.delete'] === true) {
    actionButtons.push('delete');
  }

  const columns = [
    { field: 'id', title: 'actions', buttons: actionButtons, width: 120 },
    { field: 'slug', title: 'name', width: 240 },
    { field: 'name', title: 'Display Name', width: 240 },
    { field: 'description', title: 'Description' },
  ];

  const createRoleForm = useSelector(
    state => state.userManagement.createRoleForm,
  );
  const page = useSelector(state => state.table.current_page);
  const dispatch = useDispatch();
  const [visible, setVisibility] = useState(false);
  const [colorPalette, toggleColorPalette] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [color, setColor] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveUpdateloading, setSaveUpdateLoading] = useState(false);
  const deleteModal = React.createRef();
  const child = useRef(null);
  const toggleDialog = () => {
    setVisibility(!visible);
    dispatch(clearUserRoleForm());
  };

  //create role
  const asyncCreateRole = async function (data) {
    setSaveUpdateLoading(true);

    await dispatch(newRoleAction(data));
  };

  //update role
  const asyncUpdateRole = async function (data) {
    console.log(data);
    setSaveUpdateLoading(true);

    await dispatch(updateUserRoleAction(data));
  };

  //console.log(color)
  const handleSubmit = form => {
    //dispatch(fillCreateRoleFormAction({ ...roleForm, ...form }));
    let data = {};
    data.slug = form.slug;
    data.name = form.name;
    data.description = form.description;
    data.role_color = color || createRoleForm.role_color;
    data.rank = parseInt(form.rank);

    // const formData = new FormData();
    // for (const key in {createRoleForm}) {
    //   formData.append(key, form[key]);
    // }
    if (createRoleForm.id !== 0) {
      data.id = createRoleForm.id;
      asyncUpdateRole(data)
        .then(() => {
          child.current.reDraw({});
          setSaveUpdateLoading(false);
          setSaveUpdateLoading(false);
        })
        .catch(error => {
          setSaveUpdateLoading(false);
        });
    } else {
      asyncCreateRole(data)
        .then(() => {
          child.current.reDraw({});
          setSaveUpdateLoading(false);
        })
        .catch(error => {
          setSaveUpdateLoading(false);
        });
    }

    toggleDialog();
  };

  const asyncSelectUserRole = async function (id) {
    await dispatch(getuserRoleAction(id));
    await dispatch(fillCreateRoleFormAction());
    //  let defaultColor =  createRoleForm.role_color
    // await  setColor(defaultColor)
    // console.log(defaultColor);
  };

  const asyncRoleDelete = async function (id) {
    setSaveUpdateLoading(true);

    await dispatch(deleteUserRoleAction(id));
  };

  const editRole = data => {
    //passing data from grid
    if (data.id !== undefined) {
      setLoading(true);
      asyncSelectUserRole(data.id)
        .then(() => {
          setLoading(false);
          console.log(data);
        })
        .catch(error => {
          setLoading(false);
        });
    }

    toggleDialog();
    setColor('');
  };

  const addtRole = data => {
    // if (data.id !== undefined) {
    //   dispatch(
    //     fillCreateRoleFormAction({
    //       slug: data.slug,
    //       name: data.name,
    //       description: data.description,
    //       rank: data.rank,
    //     }),
    //   );
    // }

    toggleDialog();
  };

  const handlerDeleteModal = id => {
    // dispatch( deleteUserRoleAction(id))
    setDeleteRecordId(id);
    deleteModal.current.toggleDialog();
  };

  // TODO : DELETE Action
  const onDelete = id => {
    asyncRoleDelete(id)
      .then(() => {
        child.current.reDraw({});
        setSaveUpdateLoading(false);
      })
      .catch(error => {
        setSaveUpdateLoading(false);
      });

    // .then(()=> setSaveUpdateLoading(false)) .catch((error) => {
    //   setSaveUpdateLoading(false);
    // });
  };

  function handleColor(color) {
    setColor(color.hex);
  }

  useEffect(() => {
    !history.location.pathname.includes('edit') ||
    !history.location.pathname.includes('new')
      ? dispatch(clearUserRoleForm())
      : console.log('error');
  }, []);

  return (
    <>
      {saveUpdateloading ? <Loader /> : null}
      <DrawTable
        header="User Roles"
        title="User Role"
        columns={columns}
        url="roles"
        clickedEdit={editRole}
        clickedNew={editRole}
        delete={handlerDeleteModal}
        ref={child}
        isPermissions={
          isAdmin() === true || isPermissions['role.store'] === true
            ? true
            : false
        }
      />

      <ConfirmationModal
        confirmed={() => {
          onDelete(deleteRecordId);
        }}
        title="Please confirm"
        message="Are you sure you want to delete?"
        ref={deleteModal}
      />
      {visible && (
        <Dialog
          title={
            createRoleForm.create === 'yes'
              ? 'Create User Role'
              : 'Edit User Role'
          }
          onClose={() => {
            dispatch(clearUserRoleForm());
            toggleDialog();
          }}
          width={'50vw'}
        >
          <div>
            {loading ? <Loader /> : null}
            <RoleForm
              handleSubmit={handleSubmit}
              roleForm={createRoleForm}
              toggleColorPalette={toggleColorPalette}
              colorPalette={colorPalette}
              toggleDialog={toggleDialog}
              dispatch={dispatch}
              handleColor={handleColor}
              selectedColor={color}
              clearUserRoleForm={clearUserRoleForm}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default UserRoles;
