import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function HowToMakeReducer(state = initialState.how_to_make, action) {

  switch (action.type) {
    case types.LOAD_HOW_TO_MAKE_ALL_SUCCESS:
      return action.data;  
    default:
      return state;
  }
}
