import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

const ColorButton = props => {
  return (
    <Tooltip title="Color" placement={'top'}>
      <button
        className={`action-btn action-btn--medium mr-2 k-button  `}
        style={{
          backgroundColor: props.dataItem.value,
        }}
      ></button>
    </Tooltip>
  );
};

export default ColorButton;
