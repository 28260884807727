import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

const CostCommunicationButton = props => {
  return (
    <Tooltip title="Cost Communication" placement={'top'}>
      <button
        className="action-btn action-btn--small mr-2 k-button"
        onClick={() => {
          props.clicked(props.dataItem);
        }}
      >
        <i className="ri-message-2-fill"></i>{' '}
      </button>
    </Tooltip>
  );
};

export default CostCommunicationButton;
