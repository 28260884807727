import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

const ActiveUserButton = props => {
  return props.dataItem.deleted_at === null ? (
    <Tooltip title="Active" placement={'top'}>
      <button
        className={`action-btn action-btn--medium mr-2 k-button bg-success `}
      ></button>
    </Tooltip>
  ) : (
    <Tooltip title="Inactive" placement={'top'}>
      <button
        className={`action-btn action-btn--medium mr-2 k-button bg-danger `}
      ></button>
    </Tooltip>
  );
};

export default ActiveUserButton;
