export const LOAD_SHIPPINGLOG_ALL = 'LOAD_SHIPPINGLOG_ALL';
export const LOAD_SHIPPINGLOG_ALL_SUCCESS = 'LOAD_SHIPPINGLOG_ALL_SUCCESS';
export const SEARCH_SHIPPINGLOG = 'SEARCH_SHIPPINGLOG';
export const SEARCH_SHIPPINGLOG_SUCCESS = 'SEARCH_SHIPPINGLOG_SUCCESS';
export const SEARCH_SHIPPINGLOG_ERROR = 'SEARCH_SHIPPINGLOG_ERROR';
export const LOAD_WAREHOUSES_ALL_SUCCESS = 'LOAD_WAREHOUSES_ALL_SUCCESS';
export const LOAD_LOGSTATUS_ALL_SUCCESS = 'LOAD_LOGSTATUS_ALL_SUCCESS';
export const LOAD_SHIPPINGLOG_NOTES_ALL_SUCCESS =
  'LOAD_SHIPPINGLOG_NOTES_ALL_SUCCESS';
export const LOAD_SHIPPINGLOG_DOCUMENTS_ALL_SUCCESS =
  'LOAD_SHIPPINGLOG_DOCUMENTS_ALL_SUCCESS';
export const LOAD_SHIPPING_LOG_MERGED_NOTES_ALL_SUCCESS =
  'LOAD_SHIPPING_LOG_MERGED_NOTES_ALL_SUCCESS';
export const LOAD_SHIPPINGLOG_SUCCESS = 'LOAD_SHIPPINGLOG_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const LOAD_SHIPPING_TAGS = 'LOAD_SHIPPING_TAGS';
export const LOAD_SHIPPING_LOG_MAIL_TEMPLATE =
  'LOAD_SHIPPING_LOG_MAIL_TEMPLATE';
export const LOAD_SHIPPING_LOG_SUMMARY = 'LOAD_SHIPPING_LOG_SUMMARY';
