import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import FiberNewIcon from '@material-ui/icons/FiberNew';
const NewButton = props => {
  return (
    <Tooltip title="Add New Item" placement={'top'}>
      <button
        type="button"
        className="action-btn action-btn--small mr-2 k-button"
        onClick={props.clicked}
      >
        <FiberNewIcon />
      </button>
    </Tooltip>
  );
};

export default NewButton;
