import * as types from '../../actions/cost/actionTypes';
//import initialState from '../initialState';
import { addIimages } from './cost.util';

let attachemtImages = {
  images: [],
};

export default function attachmentImages(state = attachemtImages, action) {
  switch (action.type) {
    case types.ADD_ATTACHEMENT_IMAGE:
      
      return {
        ...state,
        images:Object.assign([],action.data),
      };
    default:
      return state;
  }
}
