import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import ResetPage from 'pages/ResetPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import '@progress/kendo-theme-bootstrap/dist/all.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/reduction.scss'; 
import configureStore from './redux/configureStore';
import { Provider as ReduxProvider } from 'react-redux';
import PrivateRoute from './utils/routeGuard';
import initialState from './redux/reducers/initialState';
import { history } from './utils/history';
import UserRoles from './pages/userManagement/roles/UserRoles';
import { STATE_LOGIN, STATE_SIGNUP,STATE_FORGOT } from './components/AuthForm';
import jwt_decode from 'jwt-decode';
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const ColorPage = React.lazy(() => import('pages/masterData/ColorPage'));
const CustomerPage = React.lazy(() => import('pages/masterData/CustomerPage'));
const BrandPage = React.lazy(() => import('pages/masterData/BrandPage'));
const CountryPage = React.lazy(() => import('pages/masterData/CountryPage'));
const AssociatePackPage = React.lazy(() =>
  import('pages/masterData/AssociatePackPage'),
);
const AuditorsPage = React.lazy(() => import('pages/masterData/AuditorsPage'));
const DivisionPage = React.lazy(() => import('pages/masterData/DivisionPage'));
const BodyOptionPage = React.lazy(() =>
  import('pages/masterData/BodyOptionPage'),
);
const EditDivisionPage = React.lazy(() =>
  import('pages/masterData/EditDivisionPage'),
);
const DepartmentPage = React.lazy(() =>
  import('pages/masterData/DepartmentPage'),
);
const FabricPage = React.lazy(() => import('pages/masterData/FabricPage'));
const EditFabricPage = React.lazy(() =>
  import('pages/masterData/EditFabricPage'),
);
const HSTCodePage = React.lazy(() => import('pages/masterData/HSTCodePage'));
const EditHSTCodePage = React.lazy(() =>
  import('pages/masterData/EditHSTCodePage'),
);
const VendorsPage = React.lazy(() => import('pages/masterData/VendorsPage'));

const EditCustomerPage = React.lazy(() =>
  import('pages/masterData/EditCustomerPage'),
);
const EditVendorsPage = React.lazy(() =>
  import('pages/masterData/EditVendorsPage'),
);
const EditFactoryPage = React.lazy(() =>
  import('pages/masterData/EditFactoryPage'),
);
const ExcelSettings = React.lazy(() =>
  import('pages/masterData/ExcelSettings'),
);
const VendorDetailsPage = React.lazy(() =>
  import('pages/masterData/VendorDetailsPage'),
);
const CostPage = React.lazy(() => import('pages/cost/CostPage'));
const SyncInventoryPage = React.lazy(() =>
  import('pages/inventoryManagement/SyncInventoryPage'),
);
const MasterSyncHistoryPage = React.lazy(() =>
  import('pages/inventoryManagement/MasterSyncHistoryPage'),
);
const InventorySyncHistoryPage = React.lazy(() =>
  import('pages/inventoryManagement/InventorySyncHistoryPage'),
);
const ProductsPage = React.lazy(() =>
  import('pages/inventoryManagement/ProductsPage'),
);
const ImageRepositoryPage = React.lazy(() =>
  import('pages/inventoryManagement/ImageRepositoryPage'),
);
const SampleRoomPage = React.lazy(() =>
  import('pages/inventoryManagement/SampleRoomPage'),
);
const CheckItemsPage = React.lazy(() =>
  import('pages/inventoryManagement/CheckItemsPage'),
);
const EditCostRequestPage = React.lazy(() =>
  import('pages/costManagement/EditCostRequestPage'),
);
const UpdateCostRequestPage = React.lazy(() =>
  import('pages/costManagement/UpdateCostRequestPage'),
);
const CostDashBoard = React.lazy(() =>
  import('pages/costManagement/Dashboard'),
);
const LocationPage = React.lazy(() => import('pages/masterData/LocationPage'));
const PayMethodPage = React.lazy(() =>
  import('pages/masterData/PayMethodPage'),
);
const UserManagementPage = React.lazy(() =>
  import('pages/userManagement/user/UserManagementPage'),
);
const GeneralRolePermissions = React.lazy(() =>
  import('pages/userManagement/rolePermissions/GeneralRolePermissions'),
);

const CreateUser = React.lazy(() =>
  import('pages/userManagement/user/CreateUser'),
);
const StatusPage = React.lazy(() => import('pages/masterData/StatusPage'));
const ShippingLogPage = React.lazy(() =>
  import('pages/shipping/ShippingLogPage'),
);
const ReceivingPage = React.lazy(() => import('pages/receiving/ReceivingPage'));
const SampleManagement = React.lazy(() => import('pages/sample/Management'));

const SummaryPage = React.lazy(() => import('pages/commonPages/SummaryPage'));

const ChargebackPage= React.lazy(() => import('pages/chargeback/ChargebackEntry'));
const ChargebackSummery =React.lazy(() =>import('pages/chargeback/ChargebackSummeryPage') )


const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const store = configureStore(initialState);

class App extends React.Component {
  componentDidMount() {
    let token = localStorage.getItem('token');

    //clearInterval()
    if (token) {
      const { exp } = jwt_decode(token);
      const expirationTime = exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        history.push('/login');
        // set LocalStorage here based on response;
      }

      this.interval = setInterval(() => {
        localStorage.clear();
        history.push('/login');
      }, 21600000);
    }
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  render() {
    return (
      <ReduxProvider store={store}>
        <Router history={history} basename={getBasename()}>
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/login"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/signup"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_SIGNUP} />
                )}
              />
              <LayoutRoute
                exact
                path="/forgot"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_FORGOT} />
                )}
              />
              <LayoutRoute
                exact
                path="/reset"
                layout={EmptyLayout}
                component={ResetPage}
              />

              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <PrivateRoute exact path="/" component={DashboardPage} />

                  <PrivateRoute
                    exact
                    path="/log-dashboard"
                    component={SummaryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/customer"
                    component={CustomerPage}
                  />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/customer/new"
                      component={EditCustomerPage}
                    />
                    <PrivateRoute
                      path="/customer/:id"
                      component={EditCustomerPage}
                    />

                    <PrivateRoute
                      path="/customer-view/:id"
                      component={EditCustomerPage}
                    />
                  </Switch>

                  <PrivateRoute exact path="/brand" component={BrandPage} />
                  <PrivateRoute exact path="/country" component={CountryPage} />
                  <PrivateRoute
                    exact
                    path="/division"
                    component={DivisionPage}
                  />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/division/:id"
                      component={EditDivisionPage}
                    />
                    <PrivateRoute
                      exact
                      path="/division/new"
                      component={EditDivisionPage}
                    />
                  </Switch>
                  <PrivateRoute
                    exact
                    path="/department"
                    component={DepartmentPage}
                  />
                  <PrivateRoute
                    exact
                    path="/excel-settings"
                    component={ExcelSettings}
                  />

                  <PrivateRoute exact path="/fabric" component={FabricPage} />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/fabric/:id"
                      component={EditFabricPage}
                    />
                    <PrivateRoute
                      exact
                      path="/fabric/new"
                      component={EditFabricPage}
                    />
                  </Switch>
                  <PrivateRoute
                    exact
                    path="/hst-code"
                    component={HSTCodePage}
                  />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/hst-code/:id"
                      component={EditHSTCodePage}
                    />
                    <PrivateRoute
                      exact
                      path="/hst-code/new"
                      component={EditHSTCodePage}
                    />
                  </Switch>
                  <PrivateRoute exact path="/costing" component={CostPage} />
                  <PrivateRoute
                    exact
                    path="/sync"
                    component={SyncInventoryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/sync-master-history"
                    component={MasterSyncHistoryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/sync-inventory-history"
                    component={InventorySyncHistoryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/images"
                    component={ImageRepositoryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/external-images"
                    component={ImageRepositoryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/inventory"
                    component={ProductsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/sample-room"
                    component={SampleRoomPage}
                  />
                  <PrivateRoute
                    exact
                    path="/temp-lookup"
                    component={CheckItemsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/locations"
                    component={LocationPage}
                  />
                  <PrivateRoute
                    exact
                    path="/pay-methods"
                    component={PayMethodPage}
                  />
                  <PrivateRoute
                    exact
                    path="/associate-pack"
                    component={AssociatePackPage}
                  />
                  <PrivateRoute
                    exact
                    path="/body-options"
                    component={BodyOptionPage}
                  />

                  <PrivateRoute
                    exact
                    path="/auditors"
                    component={AuditorsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/shipping-entry"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/shipping-entry/:id"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/shipping-entry/:id/:log_status"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/archived-logs"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/shipping-summary"
                    component={SummaryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/archived-logs/:id"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/invoiced-logs"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/invoiced-logs/:id"
                    component={ShippingLogPage}
                  />
                  <PrivateRoute
                    exact
                    path="/receiving-entry"
                    component={ReceivingPage}
                  />
                  <PrivateRoute
                    exact
                    path="/receiving-entry/:id"
                    component={ReceivingPage}
                  />
                  <PrivateRoute
                    exact
                    path="/receiving-entry/:id/:log_status"
                    component={ReceivingPage}
                  />
                  <PrivateRoute
                    exact
                    path="/receiving-archived-logs/:id"
                    component={ReceivingPage}
                  />
                  <PrivateRoute
                    exact
                    path="/receiving-archived-logs"
                    component={ReceivingPage}
                  />
                  <PrivateRoute
                    exact
                    path="/receiving-summary"
                    component={SummaryPage}
                  />

                  <PrivateRoute exact path="/status" component={StatusPage} />
                  <PrivateRoute exact path="/color" component={ColorPage} />

                  <PrivateRoute exact path="/vendors" component={VendorsPage} />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/vendor/new"
                      component={EditVendorsPage}
                    />
                    {
                      <PrivateRoute
                        exact
                        path="/factory/new"
                        component={EditFactoryPage}
                      />
                    }
                    <PrivateRoute
                      exact
                      path="/factory/edit/:id"
                      component={EditFactoryPage}
                    />
                    <PrivateRoute
                      exact
                      path="/vendor/edit/:id"
                      component={EditVendorsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/vendor-view/:id"
                      component={EditVendorsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/vendor/show/:id"
                      component={VendorDetailsPage}
                    />
                  </Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/cost-request/new"
                      component={EditCostRequestPage}
                    />
                    <Route
                      exact
                      path="/cost-request/edit/:id"
                      component={UpdateCostRequestPage}
                    />
                    <Route
                      exact
                      path="/cost-request/dashboard"
                      component={CostDashBoard}
                    />
                    <PrivateRoute
                      exact
                      path="/cost-request/:id"
                      component={EditCostRequestPage}
                    />
                    <PrivateRoute
                      exact
                      path="/cost-request-view/:id"
                      component={EditCostRequestPage}
                    />
                  </Switch>
                  <Switch>
                    <Route
                      exact
                      path="/userManagement"
                      component={UserManagementPage}
                    />
                    <Route
                      exact
                      path="/userManagement/new"
                      component={CreateUser}
                    />
                    <Route
                      exact
                      path="/userManagement/edit/:id"
                      component={CreateUser}
                    />
                    <Route
                      exact
                      path="/userManagement/userPermissions"
                      component={GeneralRolePermissions}
                    />
                    <Route
                      exact
                      path="/userManagement/userRoles"
                      component={UserRoles}
                    />
                  </Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/sample/management"
                      component={SampleManagement}
                    />
                    <PrivateRoute
                      exact
                      path="/sample/development"
                      component={SampleManagement}
                    />
                  </Switch>
                  <Switch>
                    <PrivateRoute 
                    exact
                    path="/chargeback/chargeback-entry"
                    component={ChargebackPage}
                    />
                    <PrivateRoute 
                    exact
                    path="/chargeback/dashboard"
                    component={ChargebackSummery}
                    />
                    <PrivateRoute 
                    exact
                    path="/chargeback/chargeback-entry/:id/:log_status"
                    component={ChargebackPage}
                    />
                  </Switch>
                </React.Suspense>
              </MainLayout>
              <Redirect to="/" />
            </Switch>
          </GAListener>
        </Router>
      </ReduxProvider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
