import { service, handleResponse, handleError } from './ApiService';

export function getUser(user_id) {
  return service
    .get(`/user?user_id=${user_id}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getUserRole(user_id) {
  return service
    .get(`/role/${user_id}`)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteUserRole(user_id) {
  return service
    .post(`/roles/delete/${user_id}`, { user_id })
    .then(handleResponse)
    .catch(handleError);
}

export function saveUser(payload) {
  return service
    .post('/store-user', payload)

    .then(handleResponse)
    .catch(handleError);
}

export function updateUserRole(payload) {
  return service
    .post(`/roles/update/${payload.id}`, payload)
    .then(handleResponse)
    .catch(handleError);
}

export function updateUser(payload) {
  return service
    .post('/update-user', payload)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteUser(user_id) {
  return service
    .post('/delete-user', { user_id })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllUsers() {
  return service
    .get('/users?per_page=1000')
    .then(handleResponse)
    .catch(handleError);
}

export function getAllUserRoles() {
  return service.get('/roles').then(handleResponse).catch(handleError);
}

export function getAllUserTypes() {
  return service.get('/user-types').then(handleResponse).catch(handleError);
}

export function getAllLineSheets() {
  return service.get('/linesheets').then(handleResponse).catch(handleError);
}

export function createRole(payload) {
  return service
    .post('/roles', payload)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Supervisor role Id is considered as ## 16 ##
 *  @returns {Promise<AxiosResponse<T>>}
 */
export function getSupervisors() {
  return service
    .get('/users-with-roles?slug=supervisor')
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Getting role permissions for a specific user role
 * @param roleId
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getRolePermissions(roleId) {
  return service
    .get(`/permissions?role_id=${roleId}`)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Update role permissions for a specific user role
 * @returns {Promise<AxiosResponse<T>>}
 * @param payload
 */
export function updateRolePermissions(payload) {
  return service
    .post('/set-role-permission', payload)
    .then(handleResponse)
    .catch(handleError);
}

export function loadWarehouses(payload) {
  return service
    .get('/warehouses')
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
}