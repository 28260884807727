import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdDashboard,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdPages,
  MdAttachMoney,
  MdTextFields,
  MdWork,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { connect } from 'react-redux';
import { NavGroup } from './NavGroup';

const sidebarBackground = {
  backgroundColor: '#222c3c',
};

// const pageContents = [
//   { to: '/customer', name: 'Customer', exact: false, Icon: MdChevronRight },
//   { to: '/vendors', name: 'Vendors', exact: false, Icon: MdChevronRight },
//   { to: '/brand', name: 'Brands', exact: false, Icon: MdChevronRight },
//   { to: '/country', name: 'Countries', exact: false, Icon: MdChevronRight },
//   { to: '/division', name: 'Divisions', exact: false, Icon: MdChevronRight },
//   {
//     to: '/department',
//     name: 'Departments',
//     exact: false,
//     Icon: MdChevronRight,
//   },
//   { to: '/fabric', name: 'Fabrics', exact: false, Icon: MdChevronRight },
//   { to: '/hst-code', name: 'HST Code', exact: false, Icon: MdChevronRight },
//   { to: '/locations', name: 'Locations', exact: false, Icon: MdChevronRight },
//   { to: '/pay-methods', name: 'Payments', exact: false, Icon: MdChevronRight },
//   {
//     to: '/associate-pack',
//     name: 'Associate pack',
//     exact: false,
//     Icon: MdChevronRight,
//   },
//   { to: '/auditors', name: 'Auditors', exact: false, Icon: MdChevronRight },
//   { to: '/status', name: 'Status', exact: false, Icon: MdChevronRight },
// ];

// const shippingLogContents = [
//   {
//     to: '/shipping-entry',
//     name: 'Shipping Entry',
//     exact: false,
//     Icon: MdChevronRight,
//   },
//   {
//     to: '/archived-logs',
//     name: 'Archived Logs',
//     exact: false,
//     Icon: MdChevronRight,
//   },
//   {
//     to: '/invoiced-logs',
//     name: 'Invoiced Logs',
//     exact: false,
//     Icon: MdChevronRight,
//   },
// ];

// const receivingContents = [
//   {
//     to: '/receiving-entry',
//     name: 'Receiving Entry',
//     exact: false,
//     Icon: MdChevronRight,
//   },
//   {
//     to: '/receiving-archived-logs',
//     name: 'Archived Logs',
//     exact: false,
//     Icon: MdChevronRight,
//   },
// ];

// const navItems = [
//   { to: '/',
//    name: 'dashboard',
//     exact: true,
//      Icon: MdDashboard,
//      visible:isPermissions['menu.home-dashboard'] === true ? true : false },
//   // {
//   //   to: '/cards',
//   //   name: 'shipping log',
//   //   exact: false,
//   //   Icon: MdWeb,
//   //   children: [
//   //     { to: '/', name: 'Shipping Entry', exact: true, Icon: MdDashboard },
//   //     { to: '/', name: 'Archived Logs', exact: true, Icon: MdDashboard },
//   //   ],
//   // },
//   // { to: '/', name: 'Receiving Log', exact: false, Icon: MdInsertChart },
//   // { to: '/', name: 'Product Management', exact: false, Icon: MdWidgets },
// ];

// const sample = [
//   { to: '/sample/management', name: 'Sample', exact: false, Icon: MdWork },
//   {
//     to: '/sample/development',
//     name: 'Sample development',
//     exact: false,
//     Icon: MdWork,
//   },
// ];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem('display_name');
    const roles = localStorage.getItem('roles');
    const isAdmin = () => username === 'Developers Admin';
    const isFactoryUser = () => roles === 'factory-user';

    const { isPermissions } = this.props;

    // This not correct, check user roles;
    //return this.props.username === 'webadmin';  

    // let isPermissions={}
    // try{if(roleBasedAccessController.hasOwnProperty("permissions") === true ){isPermissions=roleBasedAccessController.permissions }else{
    // isPermissions={}

    // }}catch(e){isPermissions={}}
    // console.log('[permision log]' ,isPermissions);

    this.state = {
      dashboard: [
        {
          to: '/',
          name: 'dashboard',
          exact: true,
          Icon: MdDashboard,
          visible:
            isAdmin() === true || isPermissions['menu.home-dashboard'] === true
              ? true
              : false,
        },
        // {
        //   to: '/cards',
        //   name: 'shipping log',
        //   exact: false,
        //   Icon: MdWeb,
        //   children: [
        //     { to: '/', name: 'Shipping Entry', exact: true, Icon: MdDashboard },
        //     { to: '/', name: 'Archived Logs', exact: true, Icon: MdDashboard },
        //   ],
        // },
        // { to: '/', name: 'Receiving Log', exact: false, Icon: MdInsertChart },
        // { to: '/', name: 'Product Management', exact: false, Icon: MdWidgets },
      ],
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: false,
      isOpenCosting: false,
      isOpenShippingLog: false,
      isOpenReceiving: false,
      isOpenInventoryManagement: false,
      isUserManagementOpen: true,
      activeGroupName: '',
      //isSnAdmin: this.isAdmin(), // Hardcoded FIX this
      menu: [
        {
          tittle: 'Costing',
          mainIcon: 'money-dollar-circle-line',
          visible:
            isAdmin() === true ||
            isPermissions['menu.costing'] === true ||
            isPermissions['menu.cost-dashboard']
              ? true
              : false,
          navItems: [
            {
              to: '/costing',
              name: isFactoryUser() === true ? 'Cost Details' : 'Costing',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.costing'] === true
                  ? true
                  : false,
            },
            {
              to: '/cost-request/dashboard',
              name: 'Dashboard',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.cost-dashboard'] === true
                  ? true
                  : false,
            },
          ],
        },
        {
          tittle: 'Master Data',
          mainIcon: 'database-2-line',
          visible:
            isAdmin() === true ||
            isPermissions['menu.customer'] ||
            isPermissions['menu.vendors'] === true ||
            isPermissions['menu.brand'] ||
            isPermissions['menu.country'] === true ||
            isPermissions['menu.division'] === true ||
            isPermissions['menu.department'] === true ||
            isPermissions['menu.fabric'] === true ||
            isPermissions['menu.hst-code'] === true ||
            isPermissions['menu.location'] === true ||
            isPermissions['menu.payment'] === true ||
            isPermissions['menu.associate-pack'] === true ||
            isPermissions['menu.auditor'] === true ||
            isPermissions['menu.status'] === true
              ? true
              : false,
          navItems: [
            {
              to: '/customer',
              name: 'Customer',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.customer'] === true
                  ? true
                  : false,
            },
            {
              to: '/vendors',
              name: 'Vendors',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.vendors'] === true
                  ? true
                  : false,
            },
            {
              to: '/brand',
              name: 'Brands',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.brand'] === true
                  ? true
                  : false,
            },
            {
              to: '/country',
              name: 'Countries',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.country'] === true
                  ? true
                  : false,
            },
            {
              to: '/division',
              name: 'Divisions',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.Division'] === true
                  ? true
                  : false,
            },
            {
              to: '/department',
              name: 'Departments',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.department'] === true
                  ? true
                  : false,
            },
            {
              to: '/fabric',
              name: 'Fabrics',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.fabric'] === true
                  ? true
                  : false,
            },
            {
              to: '/hst-code',
              name: 'HST Code',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.hst-code'] === true
                  ? true
                  : false,
            },
            {
              to: '/locations',
              name: 'Locations',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.location'] === true
                  ? true
                  : false,
            },
            {
              to: '/pay-methods',
              name: 'Payments',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.payment'] === true
                  ? true
                  : false,
            },
            {
              to: '/associate-pack',
              name: 'Pre pack',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.associate-pack'] === true
                  ? true
                  : false,
            },
            {
              to: '/auditors',
              name: 'Auditors',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.auditor'] === true
                  ? true
                  : false,
            },
            {
              to: '/status',
              name: 'Status',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.status'] === true
                  ? true
                  : false,
            },
            {
              to: '/color',
              name: 'Color',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.status'] === true
                  ? true
                  : false,
            },
            {
              to: '/body-options',
              name: 'Body Options',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true || isPermissions['menu.status'] === true
                  ? true
                  : false,
            },
            {
              to: '/excel-settings',
              name: 'Excel Settings',
              exact: false,
              Icon: MdChevronRight,
              visible: isAdmin() === true ? true : false,
            },
          ],
        },
        {
          tittle: 'Shipping',
          mainIcon: 'ship-2-line',
          visible:
            isAdmin() === true ||
            isPermissions['menu.shipping-entry'] === true ||
            isPermissions['menu.shipping-archived-logs'] === true ||
            isPermissions['menu.invoiced-logs'] === true
              ? true
              : false,
          navItems: [
            {
              to: '/log-dashboard',
              name: 'Dashboard',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.shipping-entry'] === true ||
                isPermissions['menu.shipping-archived-logs'] === true ||
                isPermissions['menu.invoiced-logs'] === true
                  ? true
                  : false,
            },
            {
              to: '/shipping-entry/0',
              name: 'Shipping Entry',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.shipping-entry'] === true
                  ? true
                  : false,
            },
            {
              to: '/archived-logs/0',
              name: 'Archived Logs',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.shipping-archived-logs'] === true
                  ? true
                  : false,
            },
            {
              to: '/invoiced-logs/0',
              name: 'Invoiced Logs',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.invoiced-logs'] === true
                  ? true
                  : false,
            },

            // {
            //   to: '/shipping-summary',
            //   name: 'Shipping Summary',
            //   exact: false,
            //   Icon: MdChevronRight,
            //   visible:
            //     isAdmin() === true ||
            //     isPermissions['menu.shipping-entry'] === true
            //       ? true
            //       : false,
            // },
          ],
        },
        {
          tittle: 'Sample',
          mainIcon: 'flask-line',
          visible:
            isAdmin() === true || isPermissions['menu.sample'] === true
              ? true
              : false,
          Icon: MdWork,
          navItems: [
            {
              to: '/sample/management',
              name: 'Sample',
              exact: false,
              Icon: MdWork,
              visible:
                isAdmin() === true || isPermissions['menu.sample'] === true
                  ? true
                  : false,
            },
            {
              to: '/sample/development',
              name: 'Sample development',
              exact: false,
              Icon: MdWork,
              visible:
                isAdmin() === true || isPermissions['menu.sample'] === true
                  ? true
                  : false,
            },
          ],
        },
        {
          tittle: 'Receiving',
          mainIcon: 'inbox-archive-line',
          visible:
            isAdmin() === true ||
            isPermissions['menu.receiving-entry'] === true ||
            isPermissions['menu.receiving-archived-logs'] === true
              ? true
              : false,
          navItems: [
            {
              to: '/log-dashboard',
              name: 'Dashboard',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.receiving-entry'] === true ||
                isPermissions['menu.receiving-archived-logs'] === true
                  ? true
                  : false,
            },
            {
              to: '/receiving-entry/0',
              name: 'Receiving Entry',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.receiving-entry'] === true
                  ? true
                  : false,
            },
            {
              to: '/receiving-archived-logs/0',
              name: 'Archived Logs',
              exact: false,
              Icon: MdChevronRight,
              visible:
                isAdmin() === true ||
                isPermissions['menu.receiving-archived-logs'] === true
                  ? true
                  : false,
            },
            // {
            //   to: '/receiving-summary',
            //   name: 'Receiving Summary',
            //   exact: false,
            //   Icon: MdChevronRight,
            //   visible:
            //     isAdmin() === true ||
            //     isPermissions['menu.receiving-entry'] === true
            //       ? true
            //       : false,
            // },
          ],
        },
        {
          tittle: 'Inventory Management',
          mainIcon: 'home-6-line',
          visible:
            isAdmin() === true ||
            isPermissions['menu.inventory'] === true ||
            isPermissions['menu.inventory-sync'] === true ||
            isPermissions['menu.master-sync-history'] === true ||
            isPermissions['menu.inventory-sync-history'] === true ||
            isPermissions['menu.images-repository'] === true ||
            isPermissions['menu.external-images-repository'] === true
              ? true
              : false,
          Icon: MdWork,
          navItems: [
            {
              to: '/inventory',
              name: 'Inventory',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true || isPermissions['menu.inventory'] === true
                  ? true
                  : false,
            },
            {
              to: '/sync',
              name: 'Sync',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true ||
                isPermissions['menu.inventory-sync'] === true
                  ? true
                  : false,
            },
            {
              to: '/sync-master-history',
              name: 'Master Sync History',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true ||
                isPermissions['menu.master-sync-history'] === true
                  ? true
                  : false,
            },
            {
              to: '/sync-inventory-history',
              name: 'Inventory Sync History',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true ||
                isPermissions['menu.inventory-sync-history'] === true
                  ? true
                  : false,
            },
            {
              to: '/images',
              name: 'Images Repository',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true ||
                isPermissions['menu.images-repository'] === true
                  ? true
                  : false,
            },
            {
              to: '/external-images',
              name: 'External Images Repository',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true ||
                isPermissions['menu.external-images-repository'] === true
                  ? true
                  : false,
            },
            {
              to: '/sample-room',
              name: 'Sample Room',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true || isPermissions['menu.sample-room'] === true
                  ? true
                  : false,
            },
            {
              to: 'temp-lookup',
              name: 'Item Check',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true || isPermissions['menu.item-check'] === true
                  ? true
                  : false,
            },
          ],
        },
        {
          tittle: 'Chargeback',
          mainIcon: 'refund-2-line',
          visible:
            isAdmin() === true || isPermissions['dashboard.chargeback'] === true ?  true : false,
          Icon: MdAttachMoney,
          navItems:[
            {
              to: '/chargeback/dashboard',
              name: 'Dashboard',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true || isPermissions['dashboard.chargeback'] === true
                  ? true
                  : false,
           
            },
            {
              to: '/chargeback/chargeback-entry',
              name: 'Chargeback-Entry',
              exact: false,
              Icon: MdTextFields,
              visible:
                isAdmin() === true || isPermissions['dashboard.chargeback'] === true
                  ? true
                  : false,
           
            },
            // {
            //   to: '/chargeback-archived-logs/0',
            //   name: 'Archived-Logs',
            //   exact: false,
            //   Icon: MdTextFields,
            //   visible:
            //     isAdmin() === true || isPermissions['dashboard.chargeback'] === true
            //       ? true
            //       : false,
           
            // }

          ]
        },
      ],
    };
  }

  // isAdmin() {
  //   // This not correct, check user roles;
  //   //return this.props.username === 'webadmin';
  //   return this.props.username === 'admin';
  // }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  onMenuToggle = tittle => {
    this.setState({
      activeGroupName: tittle,
    });
  };

  render() {
    return (
      <aside className={`${this.props.newClass} cr-sidebar`}>
        <div className={bem.e('background')} />
        <div className={bem.e('content')} style={sidebarBackground}>
          <Navbar>
            <SourceLink className="navbar-brand sidebar-logo">
              <img src={this.props.src} height="35" className="" alt="" />
              {/* <span className="text-white">Studio Nazar</span> */}
            </SourceLink>
          </Navbar>
          <Nav
            vertical
            className="sidebar-menu"
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
          >
            {
              //this is for dashboord
              this.state.dashboard
                .filter(item => item.visible === true)
                .map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon left-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                  /**
               * unwanted codes
            
         
               */
                ))
            }
            {this.state.menu.map(m => {
              return (
                m.visible && (
                  <NavGroup
                    groupName={m.tittle}
                    bem={bem}
                    navLinks={m.navItems}
                    key={m.tittle}
                    isOpen={false}
                    mainIcon={m.mainIcon}
                    onMenuToggle={e => {
                      this.onMenuToggle(e);
                    }}
                    activeGroupName={this.state.activeGroupName}
                  />
                )
              );
            })}

            {/*<NavItem*/}
            {/*  className={*/}
            {/*    this.state.isOpenInventoryManagement*/}
            {/*      ? `${bem.e('nav-item')} expanded`*/}
            {/*      : bem.e('nav-item')*/}
            {/*  }*/}
            {/*  onClick={this.handleClick('InventoryManagement')}*/}
            {/*>*/}
            {/*  <BSNavLink className={bem.e('nav-item-collapse')}>*/}
            {/*    <div className="d-flex">*/}
            {/*      <MdPages className={bem.e('nav-item-icon')} />*/}
            {/*      <span className="">Inventory Management</span>*/}
            {/*    </div>*/}
            {/*    <MdKeyboardArrowDown*/}
            {/*      className={bem.e('nav-item-icon')}*/}
            {/*      style={{*/}
            {/*        padding: 0,*/}
            {/*        transform: this.state.isOpenInventoryManagement*/}
            {/*          ? 'rotate(0deg)'*/}
            {/*          : 'rotate(-90deg)',*/}
            {/*        transitionDuration: '0.3s',*/}
            {/*        transitionProperty: 'transform',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </BSNavLink>*/}
            {/*</NavItem>*/}
            {/*<Collapse isOpen={this.state.isOpenInventoryManagement}>*/}
            {/*  {this.state.isSnAdmin && inventoryContents.map(({ to, name, exact, Icon }, index) => (*/}
            {/*    <NavItem key={index} className={bem.e('nav-item')}>*/}
            {/*      <BSNavLink*/}
            {/*        id={`navItem-${name}-${index}`}*/}
            {/*        tag={NavLink}*/}
            {/*        to={to}*/}
            {/*        activeClassName="active"*/}
            {/*        exact={exact}*/}
            {/*        className="menu-title"*/}
            {/*      >*/}
            {/*        <Icon className={bem.e('nav-item-icon')} />*/}
            {/*        <span className="menu-title">{name}</span>*/}
            {/*      </BSNavLink>*/}
            {/*    </NavItem>*/}
            {/*  ))}*/}
            {/*</Collapse>*/}
          </Nav>
        </div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
    //role: state.auth.role,
    //roles: state.auth.roles,//change here
    isPermissions:
      state.role_based_access_controller !== null &&
      state.role_based_access_controller.permissions !== 'undifined' &&
      state.role_based_access_controller.hasOwnProperty('permissions') === true
        ? state.role_based_access_controller.permissions
        : {},
    //roles:state.auth.roles
  };
}

export default connect(mapStateToProps, null)(Sidebar);
