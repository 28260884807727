import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const EditButton = props => {
  return (
    <Tooltip title="Edit" placement={'top'}>
      <button
        type="button"
        className="action-btn action-btn--small mr-2 k-button"
        onClick={props.clicked}
      >
        <i className="ri-edit-2-line"></i>
      </button>
    </Tooltip> 
  );
};

export default EditButton;
