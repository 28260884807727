import { OPEN_PANEL, CLOSE_PANEL } from '../../actions/rightPanel/actionTypes';
import initialState from '../initialState';

export default function rightPanel(
  state = initialState.rightPanel,
  action,
) {
  switch (action.type) {
    case OPEN_PANEL:
      return { status: true };
    case CLOSE_PANEL:
      return { status: false };
    default:
      return state;
  }
}
 