import * as types from "../../actions/location/actionTypes";
import initialState from "../initialState";

export default function locationReducer(state = initialState.locations, action) {
    switch (action.type) {
        case types.LOAD_ALL_LOCATION_SUCCESS:
            return action.data;
        default:
            return state;
    }
}
