import initialState from "../initialState";
import * as types from './../../actions/bodyOptions/actionTypes'
import { cloneDeep } from "lodash";

export default function bodyOptionReducer(state = initialState.body_options, action) {
    switch (action.type) {
        case types.SAVE_BODY_OPTION:
            const cloneState = cloneDeep(state)
            console.log(action.data)
            cloneState.push(action.data)
            return cloneState
        case types.LOAD_BODY_OPTION:
            return action.data;
        default:
            return state;
    }
}
