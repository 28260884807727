import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function associatePackReducer(state = initialState.auditors, action) {
  switch (action.type) {
    case types.LOAD_ASSOCIATEPACK_ALL_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
