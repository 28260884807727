import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  closePanel,
  openPanel,
} from '../../redux/actions/rightPanel/rightPanelAction';

const ManagementButton = () => {
  
  const status = useSelector(state => state.rightPanel.status);
  const dispatch = useDispatch();
  return (
    <div>
      <Button
        look={'bare'}
        icon={'more-vertical'}
        onClick={() => {
          if (status) {
            dispatch(closePanel());
          } else {
            dispatch(openPanel());
          }
        }}
      />
    </div>
  );
};

export default ManagementButton;
