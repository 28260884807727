 export const userPermissions = {
   
//    associate-pack.delete: false,
//    ​​​
//    "associate-pack.store": false,
//    ​​​
//    "associate-pack.update": false,
//    ​​​
//    "associate-pack.view": false,
//    ​​​
//    "auditor.delete": false,
//    ​​​
//    "auditor.store": false,
//    ​​​
//    "auditor.update": false,
//    ​​​
//    "auditor.view": false,
//    ​​​
//    "brand.delete": false,
//    ​​​
//    "brand.store": false,
//    ​​​
//    "brand.update": false,
//    ​​​
//    "brand.view": false,
//    ​​​
//    "costing.delete": false,
//    ​​​
//    "costing.store": false,
//    ​​​
//    "costing.update": false,
//    ​​​
//    "costing.view": false,
//    ​​​
//    "country.delete": false,
//    ​​​
//    "country.store": false,
//    ​​​
//    "country.update": false,
//    ​​​
//    "country.view": false,
//    ​​​
//    "customer.delete": false,
//    ​​​
//    "customer.store": false,
//    ​​​
//    "customer.update": false,
//    ​​​
//    "customer.view": false,
//    ​​​
//    "dashboard.chargeback": false,
//    ​​​
//    "dashboard.receiving": false,
//    ​​​
//    "dashboard.shipping": false,
//    ​​​
//    "department .store": false,
//    ​​​
//    "department .view": false,
//    ​​​
//    "department.delete": false,
//    ​​​
//    "department.update": false,
//    ​​​
//    "division.delete": false,
//    ​​​
//    "division.store": false,
//    ​​​
//    "division.update": false,
//    ​​​
//    "division.view": false,
//    ​​​
//    "external-images-repository.delete": false,
//    ​​​
//    "external-images-repository.export-xls": false,
//    ​​​
//    "external-images-repository.view": false,
//    ​​​
//    "fabric.delete": false,
//    ​​​
//    "fabric.store": false,
//    ​​​
//    "fabric.update": false,
//    ​​​
//    "fabric.view": false,
//    ​​​
//    "factory.delete": false,
//    ​​​
//    "factory.store": false,
//    ​​​
//    "factory.update": false,
//    ​​​
//    "factory.view": false,
//    ​​​
//    "hst-code.delete": false,
//    ​​​
//    "hst-code.store": false,
//    ​​​
//    "hst-code.update": false,
//    ​​​
//    "hst-code.view": false,
//    ​​​
//    "images-repository.delete": false,
//    ​​​
//    "images-repository.view": false,
//    ​​​
//    "inventory-sync-history.delete": false,
//    ​​​
//    "inventory-sync-history.store": false,
//    ​​​
//    "inventory-sync-history.view": false,
//    ​​​
//    "inventory-sync.show": false,
//    ​​​
//    "inventory-sync.sync-inventory": false,
//    ​​​
//    "inventory-sync.sync-master": false,
//    ​​​
//    "inventory.export-xls": false,
//    ​​​
//    "inventory.print-pdf": false,
//    ​​​
//    "item-check.export-pdf": false,
//    ​​​
//    "item-check.run": false,
//    ​​​
//    "item-check.view": false,
//    ​​​
//    "linesheet.delete": false,
//    ​​​
//    "linesheet.store": false,
//    ​​​
//    "linesheet.update": false,
//    ​​​
//    "linesheet.view": false,
//    ​​​
//    "location.delete": false,
//    ​​​
//    "location.store": false,
//    ​​​
//    "location.update": false,
//    ​​​
//    "location.view": false,
//    ​​​
//    "master-sync-history.delete": false,
//    ​​​
//    "master-sync-history.store": false,
//    ​​​
//    "master-sync-history.view": false,
//    ​​​
//    "menu.Division": false,
//    ​​​
//    "menu.associate-pack": false,
//    ​​​
//    "menu.auditor": false,
//    ​​​
//    "menu.brand": false,
//    ​​​
//    "menu.costing": false,
//    ​​​
//    "menu.country": false,
//    ​​​
//    "menu.customer": false,
//    ​​​
//    "menu.department": false,
//    ​​​
//    "menu.external-images-repository": false,
//    ​​​
//    "menu.fabric": false,
//    ​​​
//    "menu.home-dashboard": false,
//    ​​​
//    "menu.hst-code": false,
//    ​​​
//    "menu.images-repository": false,
//    ​​​
//    "menu.inventory": false,
//    ​​​
//    "menu.inventory-sync": false,
//    ​​​
//    "menu.inventory-sync-history": false,
//    ​​​
//    "menu.item-check": false,
//    ​​​
//    "menu.linesheet": false,
//    ​​​
//    "menu.location": false,
//    ​​​
//    "menu.master-sync-history": false,
//    ​​​
//    "menu.orders": false,
//    ​​​
//    "menu.payment": false,
//    ​​​
//    "menu.receiving-entry": false,
//    ​​​
//    "menu.role-permission": false,
//    ​​​
//    "menu.sample": false,
//    ​​​
//    "menu.sample-room": false,
//    ​​​
//    "menu.shipping-entry": false,
//    ​​​
//    "menu.shipping-method": false,
//    ​​​
//    "menu.status": false,
//    ​​​
//    "menu.user-roles": false,
//    ​​​
//    "menu.users": false,
//    ​​​
//    "menu.vendors": false,
//    ​​​
//    "menu.warehouse": false,
//    ​​​
//    "orders.delete": false,
//    ​​​
//    "orders.store": false,
//    ​​​
//    "orders.update": false,
//    ​​​
//    "orders.view": false,
//    ​​​
//    "payment.delete": false,
//    ​​​
//    "payment.store": false,
//    ​​​
//    "payment.update": false,
//    ​​​
//    "payment.view": false,
//    ​​​
//    "permission.delete": false,
//    ​​​
//    "permission.store": false,
//    ​​​
//    "permission.update": false,
//    ​​​
//    "permission.view": false,
//    ​​​
//    "receiving.delete": false,
//    ​​​
//    "receiving.delete-status": false,
//    ​​​
//    "receiving.store": false,
//    ​​​
//    "receiving.store-status": false,
//    ​​​
//    "receiving.update": false,
//    ​​​
//    "receiving.update-status": false,
//    ​​​
//    "receiving.view": false,
//    ​​​
//    "receiving.view-status": false,
//    ​​​
//    "role.delete": false,
//    ​​​
//    "role.store": false,
//    ​​​
//    "role.update": false,
//    ​​​
//    "role.view": false,
//    ​​​
//    "sample-room.delete": false,
//    ​​​
//    "sample-room.store": false,
//    ​​​
//    "sample-room.view": false,
//    ​​​
//    "sample.delete": false,
//    ​​​
//    "sample.store": false,
//    ​​​
//    "sample.update": false,
//    ​​​
//    "sample.view": false,
//    ​​​
//    "shipping-method.delete": false,
//    ​​​
//    "shipping-method.store": false,
//    ​​​
//    "shipping-method.update": false,
//    ​​​
//    "shipping-method.view": false,
//    ​​​
//    "shipping.delete": false,
//    ​​​
//    "shipping.delete-status": false,
//    ​​​
//    "shipping.store": false,
//    ​​​
//    "shipping.store-status": false,
//    ​​​
//    "shipping.update": false,
//    ​​​
//    "shipping.update-status": false,
//    ​​​
//    "shipping.view": false,
//    ​​​
//    "shipping.view-status": false,
//    ​​​
//    "status.delete": false,
//    ​​​
//    "status.store": false,
//    ​​​
//    "status.update": false,
//    ​​​
//    "status.view": false,
//    ​​​
//    "user.delete": false,
//    ​​​
//    "user.store": false,
//    ​​​
//    "user.update": false,
//    ​​​
//    "user.view": false,
//    ​​​
//    "vendor.delete": false,
//    ​​​
//    "vendor.store": false,
//    ​​​
//    "vendor.update": false,
//    ​​​
//    "vendor.view": false,
//    ​​​
//    "warehouse.delete": false,
//    ​​​
//    "warehouse.store": false,
//    ​​​
//    "warehouse.update": false,
//    ​​​
}