import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function countryReducer(state = initialState.countries, action) {
  switch (action.type) {
    case types.LOAD_COUNTRY_ALL_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
