import * as types from "../../actions/sync/actionTypes";
import initialState from "../initialState";

export default function syncReducer(state = initialState.sync, action) {
    switch (action.type) {
        case types.GET_PENDING_FILE_COUNT:
            return action.data;
        case types.SYNC_MASTER:
            return action.data;
        case types.SYNC_INVENTORY:
            return action.data;
        case types.GET_FILE_SYNC_HISTORY:
            return action.data;
        case types.DELETE_SYNC_HISTORY:
            return action.data;
        default:
            return state;
    }
}