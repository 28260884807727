import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function fabricTypeReducer(state = initialState.fabrics_types, action) {
    if (action.type === types.LOAD_FABRIC_TYPE_ALL_SUCCESS) {
        return action.data;
    } else {
        return state;
    }
}
