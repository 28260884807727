export const cost_request = {
  excel_export_data: [],
  selectedTabIndex: {},
  id: -1,
  order_type_id: '',
  style_no: '',
  print_style_no: '',
  image_url: '',
  total_qty: 0,
  previous_style_no: '',
  target_price: 0,
  username: '',
  created_at: '',
  log_no: '',
  status_name: '',
  customer_price: 0,
  retailer_price: 0,
  estimated_delivery_date: new Date(),
  cancel_date: new Date(),
  sample_ready_date: new Date(),
  previous_order_comment: '',
  name: '',
  customer_comments: '',
  customer_id: '',
  master_style_no: '',
  ref_style_no: '',
  quantity_type: 0,
  quantity_option: 1,
  print_remarks: '',
  sample_remarks: '',
  internal_comments: '',
  show_customer_price: 0,
  show_retail_price: 0,
  show_target_price: 0,
  comment: '',
  care_and_testing: '',
  fabric_note: '',
  order_fabrics: [],
  vendors: [],
  images: [],
  associate_packages: [],
  colors: [],
  other_prices: [],
  color_packages: [],
  full_quantity: [],
  //customer_requirements:,
};
