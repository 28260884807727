import { combineReducers } from 'redux';
import addColor from './addColors/addColorsReducer';
import colorList from './colors/colorsReducer';
import courses from './courseReducer';
import authors from './authorReducer';
import apiCallsInProgress from './apiStatusReducer';
import role_based_access_controller from './userPermissions/userPermissionsReducer';
import auth from './auth/authReducer';
import customers from './customer/customerReducer';
import selectedCustomer from './customer/customerReducer';
import brands from './brand/brandReducer';
import countries from './country/countryReducer';
import divisions from './division/divisionReducer';
import selectedDivision from './division/divisionReducer';
import departments from './department/departmentReducer';
import fabrics from './fabric/fabricReducer';
import selectedFabric from './fabric/fabricReducer';
import selectedFabricTypes from './fabric/fabricTypeReducer';
import hst_codes from './hstCode/hstCodeReducer';
import selectedHSTCode from './hstCode/hstCodeReducer';
import material from './hstCode/MaterialReducer';
import howToMake from './hstCode/HowToMakeReducer';
import table from './table/tableReducer';
import body_options from './bodyOption/bodyOptionReducer';
import locations from './location/locationReducer';
import cost_request from './cost/costReducer';
import cost from './cost/costReducer';
import order_types from './orderType/orderTypeReducer';
import fabrics_types from './fabric/fabricTypeReducer';
import vendors from './vendors/vendorsReducer';
import factories from './factory/factoryReducer';
import status_types from './status/statusTypeReducer';
import userManagement from './userManagement/userManagementReducer';
import rightPanel from './rightPanel/rightPanel';
import associate_packs from './prePack/prePackReducer';
import shipping_log from './shippingLog/shippingLogReducer';
import shippingLog_notes from './shippingLog/shippingLogReducer';
import shippingLog_documents from './shippingLog/shippingLogReducer';
import selectedShippingLog from './shippingLog/shippingLogReducer';
import auditors from './auditors/auditorsReducer';
import warehouses from './warehouses/warehousesReducer';
import logStatus from './logStatus/logStatusReducer';
import notification from './notificationReducer';
import sync from './sync/syncReducer';
import product from './product/productReducer';
import receiving_log from './receivingLog/receivingLogReducer';
import selectedReceivingLog from './receivingLog/receivingLogReducer';
import receivingLog_documents from './receivingLog/receivingLogReducer';
import attachmentImages from './cost/attachmentImagesReducer';
import columns from './excelSettings/excelSettingsReducer';
import chargeback from './chargeBack/chargebackReducer';

const rootReducer = combineReducers({
  columns,
  addColor,
  colorList,
  role_based_access_controller,
  courses,
  authors,
  apiCallsInProgress,
  auth,
  customers,
  brands,
  countries,
  divisions,
  selectedDivision,
  departments,
  fabrics,
  selectedFabricTypes,
  selectedFabric,
  hst_codes,
  selectedHSTCode,
  material,
  howToMake,
  selectedCustomer,
  table,
  locations,
  body_options,
  cost_request,
  order_types,
  fabrics_types,
  vendors,
  factories,
  status_types,
  associate_packs,
  notification,
  cost,
  userManagement,
  rightPanel,
  shipping_log,
  warehouses,
  auditors,
  logStatus,
  shippingLog_notes,
  shippingLog_documents,
  selectedShippingLog,
  sync,
  product,
  receiving_log,
  selectedReceivingLog,
  receivingLog_documents,
  attachmentImages,
  chargeback,
});

export default rootReducer;
