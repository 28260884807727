import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function logStatusReducer(state = initialState.logStatus, action) {
  switch (action.type) {
    case types.LOAD_LOGSTATUS_ALL_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
