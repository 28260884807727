import React, { useState } from 'react';
import styles from './rightPropertyBar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { history } from '../../utils/history';
import {
  closePanel,
  openPanel,
} from '../../redux/actions/rightPanel/rightPanelAction';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { OutSideClickHandler } from '../Shared/OutSideClickHandler';
import { TramRounded } from '@material-ui/icons';
// import ClickOutside from '../Shared/ClickOutside';

const RightPropertyBar = () => {
  // const { ref, isComponentVisible, setIsComponentVisible } = ClickOutside(
  //   true,
  // );
  const username = localStorage.getItem('display_name');
  const isAdmin = () => username === 'Developers Admin';

  const isPermissions = useSelector(state =>
    state.role_based_access_controller !== null &&
    state.role_based_access_controller.permissions !== 'undifined' &&
    state.role_based_access_controller.hasOwnProperty('permissions') === true
      ? state.role_based_access_controller.permissions
      : {},
  );
  let menu = [
    {
      title: 'User Settings',
      visible:
        isAdmin() === true ||
        isPermissions['menu.users'] === true ||
        isPermissions['menu.role-permission'] === true ||
        isPermissions['menu.user-roles'] === true
          ? true
          : false,
      icon: 'ri-user-settings-line mr-3',
      navItems: [
        {
          to: '/userManagement/',
          name: 'User Management',
          visible:
            isAdmin() === true || isPermissions['menu.users'] === true
              ? true
              : false,
        },
        {
          to: '/userManagement/userPermissions/',
          name: 'Role Permissions',
          visible:
            isAdmin() === true || isPermissions['menu.role-permission'] === true
              ? true
              : false,
        },
        {
          to: '/userManagement/userRoles/',
          name: 'User Roles',
          visible:
            isAdmin() === true || isPermissions['menu.user-roles'] === true
              ? true
              : false,
        },
      ],
    },
    {
      title: 'Synchronization',
      visible:
        isAdmin() === true || isPermissions['menu.synchronization'] === true
          ? true
          : false,
      icon: 'ri-refresh-line mr-3',
      navItems: [
        {
          to: '#',
          name: 'Sync',
          visible:
            isAdmin() === true || isPermissions['menu.synchronization'] === true
              ? true
              : false,
        },
      ],
    },
  ];
  const status = useSelector(state => state.rightPanel.status);
  const dispatch = useDispatch();
  const onOutsideClick = () => {
    // console.log('from out side close funct : ' + status);
    if (status === true) {
      dispatch(closePanel());
    }
  };
  return (
    <OutSideClickHandler onOutsideClick={onOutsideClick} status={status}>
      {status !== null ? (
        <div
          className={`${styles.check} ${
            status ? styles.slideLeftPanel : styles.slideRightPanel
          }`}
        >
          <div className="rightsidebar-heading">
            <h3>
              <i className="ri-tools-line mr-3"></i> Settings
            </h3>
            <button
              onClick={() => {
                if (status === true) {
                  dispatch(closePanel());
                } else {
                  dispatch(openPanel());
                }
              }}
              look={'bare'}
            >
              <i className="ri-close-circle-line"></i>
            </button>
          </div>
          <PanelBar
            className="right-side-panel"
            expandMode={'single'}
            onSelect={$event => {
              history.push($event.target.props.route);
            }}
          >
            {menu
              .filter(item => item.visible === true)
              .map(item => (
                <PanelBarItem
                  key={item.title}
                  title={
                    <>
                      <i className={item.icon}></i> {item.title}
                    </>
                  }
                >
                  {item.navItems
                    .filter(item => item.visible === true)
                    .map(navItems => (
                      <PanelBarItem
                        route={navItems.to}
                        title={navItems.name}
                        key={navItems.name}
                      />
                    ))}
                </PanelBarItem>
              ))}
          </PanelBar>
        </div>
      ) : null}
    </OutSideClickHandler>
  );
};

export default RightPropertyBar;
