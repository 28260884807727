import React from 'react';
import { Row, Col, Card, CardHeader } from 'reactstrap';
import Loader from '../Shared/LoadinPanel';

const TableWrapper = props => {
  const isLoading = props.loading  ? <Loader /> : null;
  return (
    <Row>
      <Col>
        <Card className="mb-6">
          <Card body>
            {isLoading}
            {props.children}
          </Card>
        </Card>
      </Col>
    </Row>
  );
};

export default TableWrapper;
