import React, {Component} from 'react';

class ImageCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: props.dataItem.image_location 
                ? props.dataItem.image_location 
                : (props.dataItem.color 
                    ? props.location + "/" + props.dataItem.amt_item + "_" + props.dataItem.color + ".jpg" 
                    : props.location + "/" + props.dataItem.amt_item + ".jpg"),
        };
    }

    onError = () => {
        this.setState({
            url: "/img/products/boxed-bg.jpg",
        });
    };

    render() {
        const { url } = this.state;
        return (
            <td width="100px"><img width="100%" src={url} onError={this.onError} /></td>
        );
    }
}

export default ImageCell;