import axios from 'axios';
import { service, handleError, handleResponse } from './ApiService';

export function login(payload) {
  return axios
    .post(`${process.env.REACT_APP_AUTH_URL}/authenticate`, payload)
    .then(response => handleResponse(response.data))
    .catch(error => handleError(error));
}

export function logout() {}

export function resetPassword(payload) {
  return service
    .post('/change-password', payload)
    .then(response => handleResponse(response.data))
    .catch(error => handleError(error));
}
