import * as types from './actionTypes';
import * as tableApi from '../../../api/tableApi';
import { beginApiCall, apiCallError } from '../apiStatusActions';
import { addNotification } from '../notification/notificationActions';

export function loadSuccess(data) {
  return { type: types.LOAD_ALL_TABLE_SUCCESS, data };
}

export function paginate(url, payload) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return tableApi
      .getAll(url, payload)
      .then(result => {
        dispatch(loadSuccess(result.data));

        return Promise.resolve(true);
      })
      .catch(error => {
        dispatch(apiCallError(error));
        dispatch(addNotification('Something went wrong', 'error'));
        return Promise.reject(false);
      });
  };
}
