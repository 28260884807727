export const LOAD_AMT_ITEMS_SUCCESS = "LOAD_AMT_ITEMS_SUCCESS";
export const LOAD_COMPANIES_SUCCESS = "LOAD_COMPANIES_SUCCESS";
export const LOAD_DIVISIONS_SUCCESS = "LOAD_DIVISIONS_SUCCESS";
export const LOAD_SEASONS_SUCCESS = "LOAD_SEASONS_SUCCESS";
export const LOAD_VENDORS_SUCCESS = "LOAD_VENDORS_SUCCESS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const GET_SCANNED_HISTORY_SUCCESS = "GET_SCANNED_HISTORY_SUCCESS";
export const GET_SCANNED_ITEMS_SUCCESS = "GET_SCANNED_ITEMS_SUCCESS";
export const DELETE_SCANNED_BATCH_SUCCESS = "DELETE_SCANNED_BATCH_SUCCESS";
export const SAVE_SCANNED_ITEMS_SUCCESS = "SAVE_SCANNED_ITEMS_SUCCESS";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GET_LATEST_REPORT_SUCCESS = "GET_LATEST_REPORT_SUCCESS"
export const GENERATE_PDF_SUCCESS = "GENERATE_PDF_SUCCESS"