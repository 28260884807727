import * as types from '../../actions/addColors/actionTypes';
import initialState from '../initialState';

export default function associatePackReducer(
  state = initialState.addColors,
  action,
) {
  switch (action.type) {
    case types.ADD_COLOR:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}
