import * as types from '../../actions/excelSettings/actionTypes';
import initialState from '../initialState';

export default function excelExportReducer(
  state = initialState.columns,
  action,
) {
  switch (action.type) {
    case types.SELECTED_COLUMNS:
      //return action.data;
      return action.data;

    default:
      return state;
  }
}
