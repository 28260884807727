import axios from 'axios';
import { history } from '../utils/history';

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

service.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

const handleError = error => {
  if (error === 401) {
    setTimeout(() => {
      history.push('/login');
    }, 300);
  }
  return Promise.reject(error);
};

const handleResponse = response => {
  return Promise.resolve(response);
};

/* access deep object */
const check = (obj, key) => {
  return key.split('.').reduce(function (o, x) {
    return typeof o == 'undefined' || o === null ? o : o[x];
  }, obj);
};

service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.error !== undefined || res.errors !== undefined) {
      return Promise.reject('Something went wrong.');
    } else {
      return Promise.resolve(response.data);
    }
  },
  error => {
    if (check(error, 'response.data.status_code') == 401) {
      return Promise.reject(401);
    } else if (error.response.status == 422) {
      let message = '';

      for (const m of error.response.data.messages) {
        message += `<li>${m}</li>`;
      }
      return Promise.reject(message);
    } else {
      if (check(error, 'response.data.message')) {
        // error catch messages
        return Promise.reject(error.response.data.message);
      } else {
        // unknown errors
        return Promise.reject('Something went wrong.');
      }
    }
  },
);

// const blobService = axios(`${apiURL}/pdf`, {
//   method: 'POST',
//   responseType: 'blob' //Force to receive data in a Blob Format
// })
// .then(response => {
// //Create a Blob from the PDF Stream
//   const file = new Blob(
//     [response.data],
//     {type: 'application/pdf'});
// //Build a URL from the file
//   const fileURL = URL.createObjectURL(file);
// //Open the URL on new Window
//   window.open(fileURL);
// })
// .catch(error => {
//   console.log(error);
// });

export { service, handleError, handleResponse };
