import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const EmailButton = props => {
  return (
    <Tooltip title="Email" placement={'top'}>
      <button
        type="button"
        className="action-btn action-btn--small mr-2 k-button"
        onClick={props.clicked}
      >
        <i className="ri-mail-line"></i>
      </button>
    </Tooltip>
  );
};

export default EmailButton;
