import * as types from '../../actions/actionTypes';
import initialState from '../initialState';

export default function shippingLogReducer(
  state = initialState.shipping_log,
  action,
) {
  switch (action.type) {
    case types.LOAD_SHIPPINGLOG_ALL_SUCCESS:
      return { ...state, shipping_logs: action.data };
    case types.LOAD_SHIPPINGLOG_NOTES_ALL_SUCCESS:
      return { ...state, shippingLog_notes: action.data };
    case types.LOAD_SHIPPINGLOG_DOCUMENTS_ALL_SUCCESS:
      return { ...state, shippingLog_documents: action.data };
    case types.LOAD_SHIPPING_LOG_MERGED_NOTES_ALL_SUCCESS:
      return { ...state, shippingLogMergedNotes: action.data };
    case types.LOAD_SHIPPINGLOG_SUCCESS:
      return { ...state, selectedShippingLog: action.data };

    case types.LOAD_SHIPPING_TAGS:
      return {
        ...state,
        shipping_tags: action.data,
      };
    case types.LOAD_SHIPPING_LOG_MAIL_TEMPLATE:
      return {
        ...state,
        shipping_mail_template: action.data,
      };
    case types.LOAD_SHIPPING_LOG_SUMMARY:
      return {
        ...state,
        shipping_summary: action.data,
      };
    default:
      return state;
  }
}
