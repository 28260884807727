import * as types from "../../actions/status/actionTypes";
import initialState from "../initialState";

export default function statusTypeReducer(state = initialState.vendors, action) {
    if (action.type === types.LOAD_ALL_STATUS_TYPES) {
        return action.data;
    } else {
        return state;
    }
}
