import { NavLink as BSNavLink } from 'reactstrap';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { MdAttachMoney } from 'react-icons/md';
import { MdKeyboardArrowDown } from 'react-icons/md';
import React from 'react';
import PropTypes from 'prop-types';

export const NavGroupHeader = props => {
  return (
    <BSNavLink className={props.bem.e('nav-item-collapse')}>
      <div className="d-flex align-items-center">
        {/* <MdAttachMoney className={props.bem.e('nav-item-icon left-icon')} /> */}
        <div className={props.bem.e('nav-item-icon left-icon')}>
          <i className={`ri-${props.mainIcon}`}></i>
        </div>
        <span className="">{props.tittle}</span>
      </div>
      <MdKeyboardArrowDown
        className={props.bem.e('nav-item-icon  arrow')}
        style={{
          padding: 0,
          transform: props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
          transitionDuration: '0.3s',
          transitionProperty: 'transform',
          top: 23,
        }}
      />
    </BSNavLink>
  );
};

NavGroupHeader.propTypes = {
  tittle: PropTypes.string,
  isOpen: PropTypes.bool,
  bem: PropTypes.any,
};
