import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { history } from '../../utils/history';

let factoryView = history.location.pathname.includes('vendors')
  ? 'Factory View'
  : 'Info';
const ActionButton = props => (
  <Tooltip title={props.title || 'Factory View'} placement={'top'}>
    <button
      className="action-btn action-btn--small mr-2 k-button"
      onClick={() => {
        props.clicked(props.dataItem);
      }}
    >
      <i className="ri-information-line"></i>
    </button>
  </Tooltip>
);

export default ActionButton;
