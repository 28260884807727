import * as types from "../../actions/table/actionTypes";
import initialState from "../initialState";
import { map, cloneDeep } from 'lodash'

export default function tableReducer(state = initialState.table, action) {
    let cloneState;
    switch (action.type) {
        case types.LOAD_ALL_TABLE_SUCCESS:
            return action.data;
        case types.SAVE_SUCCESS:
            cloneState = cloneDeep(state)
            cloneState.data.unshift(action.data)
            if (cloneState.data.length > 10) {
                cloneState.data.pop()
            }
            cloneState.total++
            return { ...cloneState };
        case types.UPDATE_SUCCESS:
            cloneState = { ...state }
            cloneState.data = map(cloneState.data, (item) => {
                if (item.id == action.data.id) {
                    return action.data
                }
                return item
            })
            return cloneState
        case types.DELETE_SUCCESS:
            cloneState = cloneDeep(state);
            cloneState.data = map(cloneState.data, (item) => {
                if (item.id != action.data.id) {
                    return item
                }
            }).filter(Boolean)
            cloneState.total--
            return cloneState;
        default:
            return state;
    }
}
