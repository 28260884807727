import initialState from '../initialState';
import * as types from '../../actions/actionTypes';

const chargebackReducer = (state = initialState.chargeback, action) => {
  switch (action.type) {
    case types.GET_TEST_DATA:
      // console.log(state);
      return { ...state, test: 'test success' };
    case types.LOAD_CHARGEBACK_DATA:
      return { ...state, chargeback: action.data };
    case types.LOAD_SELECTED_CHARGEBACK_SUCCESS:
      return { ...state, selectedChargeback: action.data };
    case types.LOAD_SUCCESS_CHARGEBACK_TAGS:
      return { ...state, chargeback_tags: action.data };
    case types.LOAD_SUCCESS_SN_USERS:
      return { ...state, users: action.data };
    case types.LOAD_SUCCESS_CHARGEBACK_LOG_DOCS:
      return { ...state, chargebackLogDocs: action.data };
    case types.LOAD_SUCCESS_CHARGEBACK_EMAIL_TEMPLATE:
      return { ...state, chargebackEmailTemplate: action.data };
    case types.LOAD_SUCCESS_CHARGEBACK_NOTES_BY_ID:
      return { ...state, chargebackLogNotes: action.data };
    default:
      return state;
  }
};
export default chargebackReducer;
