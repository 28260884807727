import * as types from './actionTypes';
import {
  deleteUser,
  createRole,
  getAllUserRoles,
  getUser,
  getSupervisors,
  saveUser,
  getAllUserTypes,
  updateUser,
  getRolePermissions,
  updateRolePermissions,
  getUserRole,
  deleteUserRole,
  updateUserRole,
  getAllLineSheets,
  getAllUsers,
  loadWarehouses
} from '../../../api/userManagementAPI';
import {
  beginApiCall,
  apiCallError,
  apiCallSuccess,
} from '../apiStatusActions';
import { addNotification } from '../notification/notificationActions';
import { history } from '../../../utils/history';

export function loadSuccessWarehouses(data) {
  return { type: types.LOAD_WAREHOUSES_ALL_SUCCESS, data };
}

/**
 * Delete user from the DB
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
export function deleteUserAction(payload) {
  return dispatch => {
    dispatch(beginApiCall());
    return deleteUser(payload)
      .then(result => {
        dispatch(apiCallError());
        dispatch(addNotification('User deleted successfully', 'success'));
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError());
      });
  };
}
/**
 * get user from the DB
 * @param userId
 * @returns {function(*): Promise<unknown>}
 */
export function getUserAction(userId) {
  return dispatch => {
    dispatch(beginApiCall());
    return getUser(userId)
      .then(result => {
        dispatch(apiCallError());
        dispatch({ type: types.SELECT_USER, data: result.data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError());
      });
  };
}

/**
 * Get all user roles from the API
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
export function getAllUserRolesAction(payload) {
  return dispatch => {
    dispatch(beginApiCall());
    return getAllUserRoles(payload)
      .then(result => {
        dispatch(apiCallError());
        dispatch({ type: types.LOAD_ALL_ROLES, data: result.data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

/**
 * Create a user role for the system
 * @param payload - Includes name,display name ,description and a color
 * @returns {function(*): Promise<unknown>}
 */
export function newRoleAction(payload) {
  return dispatch => {
    dispatch(beginApiCall());
    return createRole(payload)
      .then(() => {
        dispatch(apiCallError());
        dispatch(addNotification('Role created successfully', 'success'));
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

//getuserRole
export function getuserRoleAction(userRoleId) {
  return dispatch => {
    dispatch(beginApiCall());
    return getUserRole(userRoleId)
      .then(result => {
        dispatch(apiCallError());
        dispatch({ type: types.SELECTED_USER_ROLE, data: result.data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError());
      });
  };
}

/**
 * Update the create role form
 * @param value - Value of the form
 * @returns {{type: string}}
 */
export function fillCreateRoleFormAction() {
  return { type: types.UPDATE_USER_ROLE_FORM };
}

export function clearUserRoleForm() {
  return { type: types.CLEAR_USER_ROLE_FORM };
}

export function updateUserRoleAction(payload) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return updateUserRole(payload)
      .then(() => {
        dispatch(apiCallError());
        dispatch(addNotification('User Role updated successfully', 'success'));
        dispatch(clearUserRoleForm());
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

//delete user role
export function deleteUserRoleAction(id) {
  return dispatch => {
    dispatch(beginApiCall());
    return deleteUserRole(id)
      .then(result => {
        dispatch(apiCallError());
        dispatch(addNotification('User Role deleted successfully', 'success'));
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError());
      });
  };
}

/**
 * Update user form in create and edit
 * @param payload
 * @returns {{data: *, type: string}}
 */
export function updateUserForm(payload) {
  return { type: types.UPDATE_USER_FORM, data: payload };
}

export function addOrderPermission(payload) {
  return { type: types.ADD_ORDER_PERMISSION, data: payload };
}

export function addInventoryAccess(payload) {
  return { type: types.ADD_INVENTORY_ACCESS, data: payload };
}

export function addOrderWithoutInventory(payload) {
  return { type: types.ADD_ORDER_WITHOUT_INVENTORY, data: payload };
}

export function addUserCanCreateLineSheet(payload) {
  return { type: types.ADD_USER_CAN_CREATE_LINESHEET, data: payload };
}

export function updateRoles() {
  return { type: types.UPDATE_USER };
}
/**
 * Change stepper up or down
 * @returns {{data: *, type: string}}
 * @param up
 */
export function moveStepper(up) {
  const stepType = up === true ? types.UP_STEPPER : types.DOWN_STEPPER;
  return { type: stepType };
}

/**
 * Change active step
 * @returns {{data: *, type: string}}
 * @param up
 */
export function changeActiveStep(step) {
  return { type: types.CHANGE_ACTIVE_STEP, data: step };
}

/**
 * add role to current user
 * @returns {{data: *, type: string}}
 * @param roleId
 */
export function addRoleToUser(roleId) {
  return { type: types.ADD_ROLE, data: roleId };
}

// export function addRoleName(name) {
//   return { type: types.ADD_ROLE_NAME, data: name };
// }
export function clearAllforms() {
  return { type: types.CLEAR_ALL_FORMS };
}

/**
 * remove role to current user
 * @returns {{data: *, type: string}}
 * @param roleId
 */
export function removeRoleFromUser(roleId) {
  return { type: types.REMOVE_ROLE, data: roleId };
}

export function addLineSheet(roleId) {
  return { type: types.ADD_LINESHEET, data: roleId };
}

export function removeLineSheet(roleId) {
  return { type: types.REMOVE_LINESHEET, data: roleId };
}
/**
 * Add warehouse to user
 * @param payload
 * @returns {{data: *, type: string}}
 */
export function addWarehouseToUser(payload) {
  return { type: types.ADD_WAREHOUSE, data: payload };
}

/**
 * Un assign warehouse from user
 * @param payload
 * @returns {{data: *, type: string}}
 */
export function removeWarehouseFromUser(payload) {
  return { type: types.REMOVE_WAREHOUSE, data: payload };
}

/**
 * Un assign warehouse from user
 * @param payload
 * @returns {{data: *, type: string}}
 */
export function setDefaultToWarehouse(payload) {
  return { type: types.SET_DEFAULT__WAREHOUSE, data: payload };
}

export function togglePassword(payload) {
  return { type: types.TOGGLE_PASSWORD, data: payload };
}

/**
 * Load supervisors
 * @returns {function(*): Promise<* | undefined | void>}
 */
export function loadAllSupervisors() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getSupervisors()
      .then(result => {
        dispatch(apiCallError());
        dispatch({ type: types.LOAD_ALL_SUPERVISORS, data: result.data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

export function getAllUsernames() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getAllUsers()
      .then(result => {
        dispatch(apiCallError());
        dispatch(loadAllUserNames(result.data));
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}
export function loadAllUserNames(payload) {
  return { type: types.LOAD_ALL_USERNAMES, data: payload };
}

/**
 * Create a user
 * @returns {function(*): Promise<* | undefined | void>}
 */
export function createUser(payload) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return saveUser(payload)
      .then(() => {
        dispatch(apiCallError());
        dispatch(addNotification('User added successfully', 'success'));
        // dispatch({ type: types.CLEAR_ALL_FORMS });
        dispatch(changeActiveStep(0));

        history.goBack();
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

/**
 * Create a user
 * @returns {function(*): Promise<* | undefined | void>}
 */
export function updateUserAction(payload) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return updateUser(payload)
      .then(() => {
        dispatch(apiCallError());
        dispatch(addNotification('User updated successfully', 'success'));
        //  dispatch({ type: types.CLEAR_ALL_FORMS });
        dispatch(changeActiveStep(0));
        history.goBack();
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

/**
 * Load all user types
 * @returns {function(*): Promise<* | undefined | void>}
 */
export function loadAllUserTypes() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getAllUserTypes()
      .then(result => {
        dispatch(apiCallError());
        dispatch({ type: types.LOAD_ALL_USER_TYPES, data: result.data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

//---------------load order line sheet
export function loadAllLineSheets() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getAllLineSheets()
      .then(result => {
        dispatch(apiCallError());
        dispatch({ type: types.LOAD_ALL_LINE_SHEETS, data: result.data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

/**
 * Clear warehouse defaults
 * @returns {{type: string, payload: *}}
 */
export function clearDefaultsOnWareHouse() {
  return { type: types.CLEAR_WAREHOUSE_DEFAULTS };
}

/**
 * Load all permissions for users types
 * @returns {function(*): Promise<* | undefined | void>}
 */
export function loadAllPermissions(roleId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getRolePermissions(roleId)
      .then(result => {
        dispatch(apiCallError());

        const data = result.data.map(record => ({
          ...record,
          menus: record.menus.map((menu, menuIndex) => ({
            ...menu,
            selected: menuIndex === 0,
          })),
        }));
        dispatch({ type: types.LOAD_ALL_PERMISSIONS, data });
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

export function togglePermission(module_index, menu_index, access_permission) {
  return dispatch => {
    dispatch({
      type: types.TOGGLE_PERMISSION,
      data: { module_index, menu_index, access_permission },
    });
  };
}

export function selectMenuInModule(moduleIndex, menuIndex) {
  return {
    type: types.SELECT_MENU_IN_MODULE,
    data: { moduleIndex, menuIndex },
  };
}

export function updatePermissionsForRole(formData) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return updateRolePermissions(formData)
      .then(() => {
        dispatch(apiCallError());
        dispatch(addNotification('User permission updated', 'success'));
      })
      .catch(error => {
        dispatch(addNotification(error, 'error'));
        dispatch(apiCallError(error));
      });
  };
}

export function getAllWarehouses(payload) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return loadWarehouses(payload)
      .then(result => {
        console.log(result.data);
        dispatch(loadSuccessWarehouses(result.data));
        dispatch(apiCallError());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
