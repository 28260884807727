import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const AddFactoryButton = props => {
  return (
    <Tooltip title="Add Factory" placement={'top'}>
      <button className="action-btn action-btn--small mr-2 k-button" onClick={() => {
        props.clicked(props.dataItem)
      }}>
        <i className="ri-add-fill"></i>
      </button>
    </Tooltip>
  );
};

export default AddFactoryButton;
