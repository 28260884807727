import * as types from '../../actions/cost/actionTypes';
import initialState from '../initialState';

export default function costReducer(state = initialState.cost_request, action) {
  switch (action.type) {
    case types.LOAD_ALL_COST_SUCCESS:
      return state;
    case types.LOAD_COST_REQUEST:
      return action.data;
    case types.LOAD_COST:
      return {
        ...state,
        ...action.data,
      };

    case types.UPDATE_COST:
      return {
        ...state,
        ...action.data,
      };

    case types.IS_EDIT_STATUS:
      return {
        ...state,
        ...action.data,
      };

    case types.GET_ALL_COST_COMMUNICATIONS:
      return {
        ...state,
        excel_export_data: action.data,
      };

    case types.GET_CUSTOMER_REQUIREMENTS:
      return {
        ...state,
        ...action.data,
      };

    case types.ADD_DASHBOARD_STATUS_NAME:
      return {
        ...state,
        dashboardStatusName: action.data,
      };

    case types.ADD_DASHBOARD_STATUS_NAME:
      return {
        ...state,
        selectedTabIndex: action.data,
      };
    case types.UPDATE_CUSTOMER_COMMENTS:
      return {
        ...state,
        // cost_request: { ...action.data.cost_request },
        customer_comments: action.data.customer_comments,
        customer_id: action.data.customer_id,
        order_type_id: action.data.order_type_id,
      };

    default:
      return state;
  }
}
