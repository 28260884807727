import * as types from "../../actions/actionTypes";
import initialState from "../initialState";

export default function warehousesReducer(state = initialState.warehouses, action) {
  switch (action.type) {
    case types.LOAD_WAREHOUSES_ALL_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
