import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Page from '../Page';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DataTable from './DataTable';
import { paginate } from '../../redux/actions/table/tableActions';
import { debounce } from 'lodash';

const DrawTable = forwardRef((props, ref) => {
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState(props.header);
  const [userQuary, setUserQuary] = useState('');
  useEffect(() => {
    //when table loaded
    setLoading(true);
    props.actions
      .paginate(props.url, { page: 1, per_page: 10, file_type: props.fileType })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.header) {
      setHeader(props.header);
    }
  }, [props.header]);

  useImperativeHandle(ref, () => ({
    //when search

    reDraw: debounce(query => {
      setUserQuary(query);
      let params = Object.assign(
        {
          page: page.skip / page.take + 1,
          per_page: page.take,
          file_type: props.fileType,
        },
        query,
      );

      setLoading(true);
      return props.actions
        .paginate(props.url, params)
        .then(() => {
          console.log('loaded');

          setLoading(false);
        })
        .catch(() => {
          console.log('error');
          setLoading(false);
        });
    }, 300),

    reloadHeader() {
      setHeader(props.header);
    },
  }));

  const onPageChange = event => {
    setPage({ skip: event.skip, take: event.take });
    setLoading(true);
    props.actions
      .paginate(props.url, {
        page: event.skip / event.take + 1,
        per_page: event.take,
        file_type: props.fileType,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <Page
      title={header}
      breadcrumbs={[{ name: props.header, active: true }]}
      className="TablePage"
    >
      <DataTable
        {...props}
        data={props.data}
        total={props.total}
        onPageChange={onPageChange}
        loading={loading}
        showImageColumn={props.showImageColumn}
        imageLocation={props.imageLocation}
      />
    </Page>
  );
});

const mapStateToProps = state => {
  return {
    data: state.table.data,
    total: state.table.total,
  };
};

const mapActionToProps = dispatch => {
  return {
    actions: {
      paginate: bindActionCreators(paginate, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapActionToProps, null, {
  forwardRef: true,
})(DrawTable);
